@font-face {
  font-family: DesignIconsFont_1;
  src: url('../fonts/DesignIconsFont_1.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: DesignIconsFont_2;
  src: url('../fonts/DesignIconsFont_2.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: block;
}