    .hexagon-main .hexagon-top{
    border-bottom-color: #123456;
            border-bottom-style: solid;
            border-bottom-width: 43.3px;
            border-left-color: transparent;
            border-left-style: solid;
            border-left-width: 75px;
            border-right-color: transparent;
            border-right-style: solid;
            border-right-width: 75px;
            width: 0;
        
}
    .hexagon-main .hexagon-middle{
    background-color: #123456;
            height: 86.6px;
            width: 150px;
        
}
    .hexagon-main .hexagon-bottom{
    border-left-color: transparent;
            border-left-style: solid;
            border-left-width: 75px;
            border-right-color: transparent;
            border-right-style: solid;
            border-right-width: 75px;
            border-top-color: #123456;
            border-top-style: solid;
            border-top-width: 43.3px;
            width: 0;
        
}