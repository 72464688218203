// Compatible with @progress/kendo-theme-bootstrap v.7.2.0

$tb-kendo-is-dark-theme: true;
$tb-kendo-border-radius: 0.325rem;
$tb-kendo-appbar-padding-x: 0.5rem;
$tb-kendo-appbar-padding-y: 0.5rem;
$tb-kendo-appbar-border-width: 0px;
$tb-kendo-appbar-font-size: 1rem;
$tb-kendo-appbar-gap: 0.5rem;
$tb-kendo-avatar-border-width: 1px;
$tb-kendo-avatar-font-size: 1rem;
$tb-kendo-badge-border-width: 1px;
$tb-kendo-bottom-nav-padding-x: 0px;
$tb-kendo-bottom-nav-font-size: 1rem;
$tb-kendo-bottom-nav-letter-spacing: 0.2px;
$tb-kendo-bottom-nav-item-padding-x: 0.5rem;
$tb-kendo-bottom-nav-item-min-width: 72px;
$tb-kendo-breadcrumb-border-width: 0px;
$tb-kendo-breadcrumb-font-size: 1rem;
$tb-kendo-breadcrumb-link-padding-x: 0.75rem;
$tb-kendo-breadcrumb-link-padding-y: 0.5rem;
$tb-kendo-breadcrumb-link-border-radius: 0.375rem;
$tb-kendo-breadcrumb-icon-link-padding-y: 0.75rem;
$tb-kendo-breadcrumb-link-icon-spacing: 0.5rem;
$tb-kendo-button-border-width: 1px;
$tb-kendo-button-padding-x: 0.75rem;
$tb-kendo-button-padding-y: 0.375rem;
$tb-kendo-card-padding-x: 1rem;
$tb-kendo-card-padding-y: 1rem;
$tb-kendo-card-border-width: 1px;
$tb-kendo-card-border-radius: 0.375rem;
$tb-kendo-card-font-size: 1rem;
$tb-kendo-card-deck-gap: 1rem;
$tb-kendo-card-title-margin-bottom: 0.75rem;
$tb-kendo-card-title-font-size: 1.25rem;
$tb-kendo-card-subtitle-margin-bottom: 0.75rem;
$tb-kendo-card-subtitle-font-size: 1rem;
$tb-kendo-card-img-max-width: 100px;
$tb-kendo-card-avatar-size: 45px;
$tb-kendo-card-actions-padding-x: 0.5rem;
$tb-kendo-card-actions-padding-y: 0.5rem;
$tb-kendo-card-actions-gap: 0.5rem;
$tb-kendo-card-deck-scroll-button-radius: 0px;
$tb-kendo-card-deck-scroll-button-offset: -1px;
$tb-kendo-checkbox-border-width: 1px;
$tb-kendo-checkbox-label-margin-x: 0.25rem;
$tb-kendo-checkbox-list-spacing: 1rem;
$tb-kendo-checkbox-list-item-padding-x: 0px;
$tb-kendo-checkbox-list-item-padding-y: 0.25rem;
$tb-kendo-chip-border-width: 1px;
$tb-kendo-chip-spacing: 0.25rem;
$tb-kendo-chip-padding-x: 0.25rem;
$tb-kendo-chip-padding-y: 0.25rem;
$tb-kendo-chip-font-size: 1rem;
$tb-kendo-color-gradient-spacer: 1rem;
$tb-kendo-color-gradient-width: 328px;
$tb-kendo-color-gradient-border-width: 1px;
$tb-kendo-color-gradient-border-radius: 0.375rem;
$tb-kendo-color-gradient-font-size: 1rem;
$tb-kendo-color-gradient-canvas-border-radius: 0.375rem;
$tb-kendo-color-gradient-canvas-gap: 0.75rem;
$tb-kendo-color-gradient-canvas-rectangle-height: 180px;
$tb-kendo-color-gradient-slider-track-size: 10px;
$tb-kendo-color-gradient-slider-border-radius: 10px;
$tb-kendo-color-gradient-slider-draghandle-border-width: 3px;
$tb-kendo-color-gradient-slider-vertical-size: 180px;
$tb-kendo-color-gradient-draghandle-width: 14px;
$tb-kendo-color-gradient-draghandle-height: 14px;
$tb-kendo-color-gradient-draghandle-border-width: 1px;
$tb-kendo-color-gradient-draghandle-outline-width: 1px;
$tb-kendo-color-gradient-canvas-draghandle-margin-y: -7px;
$tb-kendo-color-gradient-canvas-draghandle-margin-x: -7px;
$tb-kendo-color-gradient-input-width: 56px;
$tb-kendo-color-gradient-input-gap: 0.5rem;
$tb-kendo-color-gradient-input-label-gap: 0.25rem;
$tb-kendo-color-gradient-contrast-spacer: 0.5rem;
$tb-kendo-color-editor-spacer: 1rem;
$tb-kendo-color-editor-min-width: 328px;
$tb-kendo-color-editor-border-width: 1px;
$tb-kendo-color-editor-border-radius: 0.375rem;
$tb-kendo-color-editor-font-size: 1rem;
$tb-kendo-color-editor-header-actions-gap: 0.5rem;
$tb-kendo-color-editor-color-preview-width: 32px;
$tb-kendo-color-editor-color-preview-height: 12px;
$tb-kendo-color-editor-preview-gap: 0.25rem;
$tb-kendo-color-editor-color-gradient-focus-outline: 2px;
$tb-kendo-color-palette-font-size: 1rem;
$tb-kendo-color-palette-tile-width: 1.5rem;
$tb-kendo-dialog-buttongroup-padding-x: 0.5rem;
$tb-kendo-dialog-buttongroup-padding-y: 0.5rem;
$tb-kendo-dialog-buttongroup-border-width: 1px;
$tb-kendo-dialog-button-spacing: 0.5rem;
$tb-kendo-dock-manager-border-width: 1px;
$tb-kendo-dock-manager-pane-header-padding-y: 0.5rem;
$tb-kendo-dock-manager-pane-header-padding-x: 1rem;
$tb-kendo-dock-manager-pane-title-font-size: 1.25rem;
$tb-kendo-dock-manager-pane-content-padding-y: 0.5rem;
$tb-kendo-dock-manager-tabbed-pane-padding-y: 0.875rem;
$tb-kendo-dock-manager-unpinned-container-width: 300px;
$tb-kendo-dock-indicator-padding: 0.375rem;
$tb-kendo-dock-indicator-outline-width: 1px;
$tb-kendo-dock-manager-dock-preview-border-width: 1px;
$tb-kendo-dock-manager-dock-preview-border-radius: 0.25rem;
$tb-kendo-dropdowntree-popup-padding-x: 0.5rem;
$tb-kendo-dropdowntree-popup-padding-y: 0.5rem;
$tb-kendo-dropzone-padding-x: 0.5rem;
$tb-kendo-dropzone-padding-y: 0.5rem;
$tb-kendo-dropzone-border-width: 1px;
$tb-kendo-dropzone-min-height: 220px;
$tb-kendo-dropzone-font-size: 1rem;
$tb-kendo-dropzone-icon-spacing: 1.5rem;
$tb-kendo-dropzone-hint-spacing: 0.5rem;
$tb-kendo-dropzone-note-font-size: 0.75rem;
$tb-kendo-editor-border-width: 1px;
$tb-kendo-editor-font-size: 1rem;
$tb-kendo-editor-resize-handle-size: 8px;
$tb-kendo-editor-resize-handle-border-width: 1px;
$tb-kendo-editor-selectednode-outline-width: 2px;
$tb-kendo-expander-spacing-y: 0.75rem;
$tb-kendo-expander-border-width: 1px;
$tb-kendo-expander-font-size: 1rem;
$tb-kendo-expander-header-padding-x: 1.5rem;
$tb-kendo-expander-header-padding-y: 1rem;
$tb-kendo-expander-indicator-margin-x: 0.75rem;
$tb-kendo-expander-content-padding-x: 1.5rem;
$tb-kendo-expander-content-padding-y: 1.5rem;
$tb-kendo-filter-padding-x: 1rem;
$tb-kendo-filter-padding-y: 0.5rem;
$tb-kendo-filter-line-size: 1px;
$tb-kendo-fab-border-width: 1px;
$tb-kendo-fab-font-size: 1rem;
$tb-kendo-fab-padding-x: 1rem;
$tb-kendo-fab-icon-padding-x: 0.25rem;
$tb-kendo-fab-icon-spacing: 0.125rem;
$tb-kendo-fab-items-padding-x: 0px;
$tb-kendo-fab-items-padding-y: 1rem;
$tb-kendo-fab-item-text-padding-x: 0.25rem;
$tb-kendo-fab-item-text-border-width: 1px;
$tb-kendo-fab-item-text-border-radius: 4px;
$tb-kendo-fab-item-text-font-size: 0.75rem;
$tb-kendo-fab-item-text-line-height: 14px;
$tb-kendo-fab-item-icon-padding-x: 0.75rem;
$tb-kendo-fab-outline-width: 3px;
$tb-kendo-fab-item-outline-width: 3px;
$tb-kendo-floating-label-font-size: 1rem;
$tb-kendo-form-spacer: 2rem;
$tb-kendo-form-font-size: 1rem;
$tb-kendo-form-fieldset-padding: 0px;
$tb-kendo-form-legend-padding: 0px;
$tb-kendo-form-legend-font-size: 0.875rem;
$tb-kendo-form-label-margin-bottom: 0.5rem;
$tb-kendo-form-hint-font-size: 0.75rem;
$tb-kendo-form-hint-margin-top: 4px;
$tb-kendo-horizontal-form-label-padding-top: 5px;
$tb-kendo-horizontal-form-label-margin-x: 10px;
$tb-kendo-label-optional-margin-x: 6px;
$tb-kendo-label-optional-font-size: 0.75rem;
$tb-kendo-fieldset-margin: 30px;
$tb-kendo-grid-padding-x: 0.5rem;
$tb-kendo-grid-padding-y: 0.5rem;
$tb-kendo-grid-edit-cell-padding-y: 5px;
$tb-kendo-grid-selection-aggregates-border-width: 1px;
$tb-kendo-grid-selection-aggregates-spacing: 0.5rem;
$tb-kendo-grid-row-resizer-height: 0.125rem;
$tb-kendo-input-border-width: 1px;
$tb-kendo-input-padding-x: 0.75rem;
$tb-kendo-input-padding-y: 0.375rem;
$tb-kendo-input-font-size: 1rem;
$tb-kendo-listbox-spacing: 0.5rem;
$tb-kendo-listbox-button-spacing: 1rem;
$tb-kendo-listbox-default-height: 200px;
$tb-kendo-listbox-border-width: 1px;
$tb-kendo-listbox-font-size: 1rem;
$tb-kendo-listbox-drop-hint-width: 1px;
$tb-kendo-listview-padding-x: 0.25rem;
$tb-kendo-listview-padding-y: 0.25rem;
$tb-kendo-listview-border-width: 1px;
$tb-kendo-listview-font-size: 1rem;
$tb-kendo-listview-item-padding-x: 0.25rem;
$tb-kendo-listview-item-padding-y: 0.25rem;
$tb-kendo-loader-container-panel-border-width: 1px;
$tb-kendo-loader-container-panel-border-radius: 0.375rem;
$tb-kendo-menu-popup-border-width: 1px;
$tb-kendo-menu-popup-item-padding-x: 1rem;
$tb-kendo-menu-popup-item-padding-y: 0.25rem;
$tb-kendo-menu-popup-item-spacing: 0px;
$tb-kendo-notification-group-gap: 0.75rem;
$tb-kendo-notification-padding-x: 1rem;
$tb-kendo-notification-padding-y: 1rem;
$tb-kendo-notification-border-width: 1px;
$tb-kendo-notification-border-radius: 0.375rem;
$tb-kendo-notification-font-size: 0.875rem;
$tb-kendo-notification-icon-spacing: 0.5rem;
$tb-kendo-pager-border-width: 1px;
$tb-kendo-pager-font-size: 1rem;
$tb-kendo-pager-item-border-width: 1px;
$tb-kendo-pager-item-border-radius: 0.25rem;
$tb-kendo-pager-item-spacing: -1px;
$tb-kendo-popup-border-width: 1px;
$tb-kendo-popup-border-radius: 0.375rem;
$tb-kendo-popup-font-size: 1rem;
$tb-kendo-popup-content-padding-x: 0.5rem;
$tb-kendo-popup-content-padding-y: 0.5rem;
$tb-kendo-progressbar-height: 1rem;
$tb-kendo-progressbar-border-width: 0px;
$tb-kendo-progressbar-font-size: 0.75rem;
$tb-kendo-radio-border-width: 1px;
$tb-kendo-radio-label-margin-x: 0.25rem;
$tb-kendo-radio-list-spacing: 1rem;
$tb-kendo-radio-list-item-padding-x: 0px;
$tb-kendo-radio-list-item-padding-y: 0.25rem;
$tb-kendo-rating-font-size: 1rem;
$tb-kendo-rating-container-margin-x: 0.5rem;
$tb-kendo-rating-item-padding-x: 0.5rem;
$tb-kendo-rating-item-padding-y: 0.5rem;
$tb-kendo-rating-label-margin-x: 1rem;
$tb-kendo-rating-label-margin-y: 0.5rem;
$tb-kendo-scrollview-border-width: 1px;
$tb-kendo-scrollview-font-size: 1rem;
$tb-kendo-scrollview-pagebutton-size: 10px;
$tb-kendo-scrollview-pager-item-spacing: 20px;
$tb-kendo-scrollview-pager-item-border-width: 0px;
$tb-kendo-skeleton-text-border-radius: 0.375rem;
$tb-kendo-skeleton-circle-border-radius: 9999px;
$tb-kendo-switch-track-border-width: 1px;
$tb-kendo-table-border-width: 1px;
$tb-kendo-table-cell-vertical-border-width: 1px;
$tb-kendo-table-cell-horizontal-border-width: 0px;
$tb-kendo-tabstrip-item-padding-x: 1rem;
$tb-kendo-tabstrip-item-padding-y: 0.5rem;
$tb-kendo-tabstrip-item-border-width: 1px;
$tb-kendo-tabstrip-item-border-radius: 0.25rem;
$tb-kendo-tabstrip-content-padding-x: 1rem;
$tb-kendo-tabstrip-content-padding-y: 1rem;
$tb-kendo-tabstrip-content-border-width: 1px;
$tb-kendo-tile-layout-border-width: 0px;
$tb-kendo-tile-layout-padding-x: 1rem;
$tb-kendo-tile-layout-hint-border-width: 1px;
$tb-kendo-toolbar-border-width: 1px;
$tb-kendo-toolbar-font-size: 1rem;
$tb-kendo-toolbar-flat-border-width: 1px;
$tb-kendo-tooltip-padding-y: 0.25rem;
$tb-kendo-tooltip-padding-x: 0.5rem;
$tb-kendo-tooltip-border-width: 0px;
$tb-kendo-tooltip-border-radius: 0.375rem;
$tb-kendo-tooltip-font-size: 0.875rem;
$tb-kendo-tooltip-title-font-size: 1.09375rem;
$tb-kendo-tooltip-callout-size: 0.4rem;
$tb-kendo-treeview-font-size: 1rem;
$tb-kendo-treeview-indent: 24px;
$tb-kendo-treeview-item-padding-x: 0.75rem;
$tb-kendo-treeview-item-padding-y: 0.25rem;
$tb-kendo-treeview-item-border-width: 0px;
$tb-kendo-treeview-item-border-radius: 0.25rem;
$tb-kendo-font-size: 1rem;
$tb-kendo-upload-border-width: 1px;
$tb-kendo-upload-font-size: 1rem;
$tb-kendo-upload-max-height: 300px;
$tb-kendo-upload-dropzone-padding-x: 0.5rem;
$tb-kendo-upload-dropzone-padding-y: 0.5rem;
$tb-kendo-upload-item-padding-x: 1rem;
$tb-kendo-upload-multiple-items-spacing: 12px;
$tb-kendo-upload-validation-font-size: 11px;
$tb-kendo-upload-icon-spacing: 0.5rem;
$tb-kendo-window-border-width: 1px;
$tb-kendo-window-border-radius: 0.375rem;
$tb-kendo-window-font-size: 1rem;
$tb-kendo-window-titlebar-padding-x: 1rem;
$tb-kendo-window-titlebar-padding-y: 1rem;
$tb-kendo-window-title-font-size: 1.25rem;
$tb-kendo-window-actions-gap: 0.5rem;
$tb-kendo-window-inner-padding-x: 1rem;
$tb-kendo-window-inner-padding-y: 1rem;
$tb-kendo-window-buttongroup-padding-x: 0.5rem;
$tb-kendo-window-buttongroup-padding-y: 0.5rem;
$tb-kendo-window-buttongroup-border-width: 1px;
$tb-kendo-bottom-nav-padding-y: $tb-kendo-bottom-nav-padding-x;
$tb-kendo-bottom-nav-gap: $tb-kendo-bottom-nav-padding-x;
$tb-kendo-breadcrumb-icon-link-padding-x: $tb-kendo-breadcrumb-icon-link-padding-y;
$tb-kendo-card-header-padding-x: $tb-kendo-card-padding-x;
$tb-kendo-card-header-padding-y: $tb-kendo-card-padding-y;
$tb-kendo-card-header-border-width: $tb-kendo-card-border-width;
$tb-kendo-card-body-padding-x: $tb-kendo-card-padding-x;
$tb-kendo-card-body-padding-y: $tb-kendo-card-padding-x;
$tb-kendo-card-footer-padding-x: $tb-kendo-card-padding-x;
$tb-kendo-card-footer-padding-y: $tb-kendo-card-padding-y;
$tb-kendo-card-footer-border-width: $tb-kendo-card-border-width;
$tb-kendo-card-avatar-spacing: $tb-kendo-card-header-padding-x;
$tb-kendo-color-gradient-padding-y: $tb-kendo-color-gradient-spacer;
$tb-kendo-color-gradient-padding-x: $tb-kendo-color-gradient-padding-y;
$tb-kendo-color-gradient-gap: $tb-kendo-color-gradient-spacer;
$tb-kendo-color-editor-header-padding-y: $tb-kendo-color-editor-spacer;
$tb-kendo-color-editor-header-padding-x: $tb-kendo-color-editor-header-padding-y;
$tb-kendo-color-editor-views-padding-y: $tb-kendo-color-editor-spacer;
$tb-kendo-color-editor-views-padding-x: $tb-kendo-color-editor-views-padding-y;
$tb-kendo-color-editor-views-gap: $tb-kendo-color-editor-spacer;
$tb-kendo-color-palette-tile-height: $tb-kendo-color-palette-tile-width;
$tb-kendo-dock-manager-pane-content-padding-x: $tb-kendo-dock-manager-pane-header-padding-x;
$tb-kendo-dock-manager-tabbed-pane-padding-x: $tb-kendo-dock-manager-pane-content-padding-y;
$tb-kendo-fab-padding-y: $tb-kendo-fab-padding-x;
$tb-kendo-fab-icon-padding-y: $tb-kendo-fab-icon-padding-x;
$tb-kendo-fab-item-text-padding-y: $tb-kendo-fab-item-text-padding-x;
$tb-kendo-fab-item-icon-padding-y: $tb-kendo-fab-item-icon-padding-x;
$tb-kendo-grid-header-padding-x: $tb-kendo-grid-padding-x;
$tb-kendo-grid-header-padding-y: $tb-kendo-grid-padding-y;
$tb-kendo-grid-grouping-header-padding-x: $tb-kendo-grid-padding-y;
$tb-kendo-grid-grouping-header-padding-y: $tb-kendo-grid-grouping-header-padding-x;
$tb-kendo-grid-cell-padding-x: $tb-kendo-grid-padding-x;
$tb-kendo-grid-cell-padding-y: $tb-kendo-grid-padding-y;
$tb-kendo-grid-filter-cell-padding-x: $tb-kendo-grid-padding-y;
$tb-kendo-grid-filter-cell-padding-y: $tb-kendo-grid-filter-cell-padding-x;
$tb-kendo-grid-edit-cell-padding-x: $tb-kendo-grid-cell-padding-x;
$tb-kendo-menu-button-arrow-padding-x: $tb-kendo-button-padding-y;
$tb-kendo-popover-border-width: $tb-kendo-card-border-width;
$tb-kendo-popover-border-radius: $tb-kendo-card-border-radius;
$tb-kendo-popover-font-size: $tb-kendo-card-font-size;
$tb-kendo-popover-header-padding-x: $tb-kendo-card-header-padding-x;
$tb-kendo-popover-header-padding-y: $tb-kendo-card-header-padding-y;
$tb-kendo-popover-header-border-width: $tb-kendo-card-header-border-width;
$tb-kendo-popover-body-padding-x: $tb-kendo-card-body-padding-x;
$tb-kendo-popover-body-padding-y: $tb-kendo-card-body-padding-y;
$tb-kendo-popover-actions-border-width: $tb-kendo-popover-border-width;
$tb-kendo-popover-callout-border-width: $tb-kendo-popover-border-width;
$tb-kendo-split-button-arrow-padding-x: $tb-kendo-button-padding-y;
$tb-kendo-split-button-arrow-padding-y: $tb-kendo-button-padding-y;
$tb-kendo-tile-layout-padding-y: $tb-kendo-tile-layout-padding-x;
$tb-kendo-tile-layout-card-border-width: $tb-kendo-card-border-width;
$tb-kendo-tile-layout-hint-border-radius: $tb-kendo-card-border-radius;
$tb-figma-bg-color: #ffffff;
$tb-kendo-body-text: #212529;
$tb-kendo-subtle-text: #6c757d;
$tb-kendo-disabled-text: #adb5bd;
$tb-kendo-base-bg: #f8f9fa;
$tb-kendo-base-text: #212529;
$tb-kendo-base-border: #e4e5e6;
$tb-kendo-hover-bg: #e9ecef;
$tb-kendo-selected-bg: #A797E6;
$tb-kendo-selected-text: white;
$tb-kendo-selected-border: #0c65e9;
$tb-kendo-appbar-light-bg: #f8f9fa;
$tb-kendo-appbar-light-text: black;
$tb-kendo-appbar-dark-bg: #212529;
$tb-kendo-appbar-dark-text: white;
$tb-kendo-bottom-nav-flat-text: #212529;
$tb-kendo-bottom-nav-flat-bg: #ffffff;
$tb-kendo-bottom-nav-flat-border: #dee2e6;
$tb-kendo-breadcrumb-bg: #ffffff;
$tb-kendo-breadcrumb-text: #212529;
$tb-kendo-breadcrumb-border: #dee2e6;
$tb-kendo-breadcrumb-link-text: #0d6efd;
$tb-kendo-breadcrumb-link-hover-text: #0a58ca;
$tb-kendo-breadcrumb-root-link-text: #0d6efd;
$tb-kendo-breadcrumb-root-link-hover-text: #0a58ca;
$tb-kendo-breadcrumb-current-item-text: #6c757d;
$tb-figma-primary-button-bg: #f87f5f;
$tb-kendo-button-text: #212529;
$tb-kendo-button-hover-bg: #ced3db;
$tb-kendo-button-hover-border: #c7cdd5;
$tb-kendo-button-active-bg: #c7cdd5;
$tb-kendo-button-active-border: #bfc6d0;
$tb-kendo-button-selected-bg: #0257d5;
$tb-kendo-button-selected-text: white;
$tb-kendo-button-selected-border: #0252c9;
$tb-kendo-button-focus-border: #bfc6d0;
$tb-kendo-card-bg: #ffffff;
$tb-kendo-card-text: #212529;
$tb-kendo-card-border: #dee2e6;
$tb-kendo-card-header-bg: #f8f9fa;
$tb-kendo-card-header-text: #212529;
$tb-kendo-card-subtitle-text: #6c757d;
$tb-kendo-series-a: #0d6efd;
$tb-kendo-series-b: #6f42c1;
$tb-kendo-series-c: #20c997;
$tb-kendo-series-d: #198754;
$tb-kendo-series-e: #ffc107;
$tb-kendo-series-f: #dc3545;
$tb-kendo-chart-major-lines: rgba(0, 0, 0, 0.08);
$tb-kendo-chart-minor-lines: rgba(0, 0, 0, 0.04);
$tb-kendo-checkbox-bg: #ffffff;
$tb-kendo-checkbox-border: #ced4da;
$tb-kendo-color-primary: #49419A;
$tb-kendo-checkbox-checked-text: white;
$tb-kendo-checkbox-focus-border: #86b7fe;
$tb-kendo-checkbox-invalid-text: #dc3545;
$tb-kendo-checkbox-invalid-border: #dc3545;
$tb-kendo-chip-base-bg: #ffffff;
$tb-figma-base-bg: #e4e7eb;
$tb-kendo-chip-solid-border: #212529;
$tb-kendo-chip-outline-bg: #ffffff;
$tb-kendo-chip-outline-hover-text: white;
$tb-kendo-color-gradient-text: #212529;
$tb-kendo-color-gradient-bg: #ffffff;
$tb-kendo-color-gradient-border: #dee2e6;
$tb-kendo-color-gradient-draghandle-bg: transparent;
$tb-kendo-color-gradient-draghandle-border: rgba(255, 255, 255, 0.8);
$tb-kendo-color-gradient-draghandle-focus-shadow: #000000;
$tb-kendo-color-gradient-draghandle-hover-shadow: #000000;
$tb-kendo-color-gradient-input-label-text: #6c757d;
$tb-kendo-color-primary-contrast: white;
$tb-kendo-color-secondary: #6c757d;
$tb-kendo-color-tertiary: #6f42c1;
$tb-figma-colors-secondary-button-selected: #c8c6e1;
$tb-kendo-color-success: #02864A;
$tb-kendo-color-warning: #FB8D1A;
$tb-kendo-color-error: #E8083E;
$tb-kendo-color-dark: #212529;
$tb-kendo-color-light: #f8f9fa;
$tb-kendo-color-inverse: #212529;
$tb-kendo-color-editor-text: #212529;
$tb-kendo-color-editor-bg: #ffffff;
$tb-kendo-color-editor-border: #dee2e6;
$tb-kendo-color-editor-color-gradient-focus-outline-color: rgba(0, 0, 0, 0.3);
$tb-kendo-component-bg: #ffffff;
$tb-kendo-component-text: #212529;
$tb-kendo-component-border: #dee2e6;
$tb-kendo-dock-indicator-hover-text: #ffffff;
$tb-kendo-dock-manager-dock-preview-bg: rgba(13, 110, 253, 0.16);
$tb-kendo-dropzone-bg: #f8f9fa;
$tb-kendo-dropzone-icon-text: #afb1b2;
$tb-kendo-dropzone-note-text: #6c757d;
$tb-kendo-editor-placeholder-text: #6c757d;
$tb-kendo-editor-highlighted-bg: #cfe2ff;
$tb-kendo-editor-resize-handle-border: #000000;
$tb-kendo-editor-resize-handle-bg: #ffffff;
$tb-kendo-editor-selectednode-outline-color: #88ccff;
$tb-kendo-expander-header-bg: transparent;
$tb-kendo-expander-header-hover-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-expander-header-sub-title-text: #6c757d;
$tb-kendo-filter-preview-operator-text: #6c757d;
$tb-kendo-grid-bg: #ffffff;
$tb-kendo-grid-text: #212529;
$tb-kendo-grid-border: #dee2e6;
$tb-kendo-grid-header-bg: #f8f9fa;
$tb-kendo-grid-header-text: #212529;
$tb-kendo-grid-header-border: #dee2e6;
$tb-kendo-grid-footer-bg: #f8f9fa;
$tb-kendo-grid-footer-text: #212529;
$tb-kendo-grid-footer-border: #dee2e6;
$tb-kendo-grid-alt-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-grid-hover-bg: rgba(0, 0, 0, 0.08);
$tb-kendo-grid-selected-bg: rgba(13, 110, 253, 0.25);
$tb-kendo-grid-selected-text: #212529;
$tb-kendo-grid-row-resizer-hover-bg: rgba(0, 0, 0, 0.12);
$tb-kendo-input-bg: #ffffff;
$tb-kendo-input-text: #212529;
$tb-kendo-input-border: #ced4da;
$tb-kendo-input-focus-bg: #ffffff;
$tb-kendo-input-focus-text: #212529;
$tb-kendo-input-focus-border: #86b7fe;
$tb-kendo-input-outline-border: rgba(33, 37, 41, 0.5);
$tb-kendo-input-placeholder-text: #6c757d;
$tb-kendo-input-prefix-text: #6c757d;
$tb-kendo-input-suffix-text: #6c757d;
$tb-kendo-input-invalid-border: #dc3545;
$tb-kendo-input-valid-border: #198754;
$tb-kendo-list-no-data-text: #6c757d;
$tb-kendo-list-option-label-text: #6c757d;
$tb-kendo-listview-item-selected-bg: rgba(13, 110, 253, 0.25);
$tb-kendo-loader-secondary-bg: #212529;
$tb-kendo-loader-container-panel-bg: #ffffff;
$tb-kendo-menu-popup-bg: #ffffff;
$tb-kendo-menu-popup-text: #212529;
$tb-kendo-menu-popup-border: #dee2e6;
$tb-kendo-pager-bg: #f8f9fa;
$tb-kendo-pager-text: #212529;
$tb-kendo-pager-border: #dee2e6;
$tb-kendo-picker-outline-border: rgba(33, 37, 41, 0.5);
$tb-kendo-picker-outline-hover-text: white;
$tb-kendo-picker-flat-hover-bg: rgba(33, 37, 41, 0.08);
$tb-kendo-progressbar-bg: #e9ecef;
$tb-kendo-progressbar-text: black;
$tb-kendo-rating-icon-text: #6c757d;
$tb-kendo-scrollview-navigation-color: white;
$tb-kendo-scrollview-navigation-bg: rgba(0, 0, 0, 0);
$tb-kendo-scrollview-light-bg: rgba(255, 255, 255, 0.4);
$tb-kendo-scrollview-dark-bg: rgba(0, 0, 0, 0.4);
$tb-kendo-skeleton-item-bg: rgba(33, 37, 41, 0.2);
$tb-kendo-skeleton-wave-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-switch-off-track-border: #adb5bd;
$tb-kendo-switch-off-track-focus-border: #86b7fe;
$tb-kendo-switch-off-track-disabled-bg: #e9ecef;
$tb-kendo-switch-off-thumb-bg: #adb5bd;
$tb-kendo-switch-on-track-disabled-bg: #86b7fe;
$tb-kendo-switch-on-thumb-bg: white;
$tb-kendo-table-header-bg: #f8f9fa;
$tb-kendo-table-header-text: #212529;
$tb-kendo-table-header-border: #dee2e6;
$tb-kendo-table-alt-row-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-table-hover-bg: rgba(0, 0, 0, 0.08);
$tb-kendo-table-selected-bg: rgba(13, 110, 253, 0.25);
$tb-kendo-tabstrip-item-text: #0d6efd;
$tb-kendo-tabstrip-item-hover-text: #0a58ca;
$tb-kendo-tabstrip-item-hover-border: #e9ecef;
$tb-kendo-tile-layout-bg: #f8f9fa;
$tb-kendo-tile-layout-hint-bg: rgba(255, 255, 255, 0.2);
$tb-kendo-toolbar-bg: #f8f9fa;
$tb-kendo-toolbar-text: #212529;
$tb-kendo-toolbar-border: #dee2e6;
$tb-kendo-tooltip-bg: #000000;
$tb-kendo-tooltip-text: white;
$tb-kendo-treeview-loadmore-bg: transparent;
$tb-kendo-treeview-loadmore-text: #0d6efd;
$tb-kendo-treeview-loadmore-hover-bg: transparent;
$tb-kendo-treeview-loadmore-hover-text: #0a58ca;
$tb-kendo-treeview-loadmore-focus-bg: transparent;
$tb-kendo-treeview-loadmore-focus-text: #0a58ca;
$tb-kendo-upload-dropzone-text: #212529;
$tb-kendo-upload-dropzone-bg: #f8f9fa;
$tb-kendo-upload-dropzone-hover-bg: #f4f5f6;
$tb-kendo-upload-status-text: #6c757d;
$tb-kendo-upload-icon-color: #6c757d;
$tb-figma-base-border: #e4e7eb;
$tb-figma-base-text: #212529;
$tb-figma-body-primary: #edecf3;
$tb-figma-chart-tooltip-bg: #f9f9f9;
$tb-figma-component-border: #dee2e6;
$tb-figma-component-text-white: #ffffff;
$tb-figma-dark: #212529;
$tb-figma-gantt-toolbar-focus: rgba(0, 0, 0, 0.08);
$tb-figma-gray-100: #f8f9fa;
$tb-figma-gray-200: #e9ecef;
$tb-figma-gray-300: #dee2e6;
$tb-figma-gray-400: #ced4da;
$tb-figma-gray-500: #adb5bd;
$tb-figma-gray-600: #6c757d;
$tb-figma-gray-800: #343a40;
$tb-figma-hovered-bg: #ced3db;
$tb-figma-hovered-border: #c7cdd5;
$tb-figma-inputborder-focused: #652d90;
$tb-figma-light: #f8f9fa;
$tb-figma-link-hover: #652d90;
$tb-figma-notification-error-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%);
$tb-figma-notification-error-border: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
$tb-figma-notification-error-text: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
$tb-figma-notification-info-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%);
$tb-figma-notification-info-border: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
$tb-figma-notification-info-text: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
$tb-figma-notification-success-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%);
$tb-figma-notification-success-border: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
$tb-figma-notification-success-text: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
$tb-figma-notification-warning-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%);
$tb-figma-notification-warning-border: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
$tb-figma-notification-warning-text: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
$tb-figma-pager-number-hover-border: #d6d9dc;
$tb-figma-primary: #49419a;
$tb-figma-scheduler-event-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
$tb-figma-tertiary: #6f42c1;
$tb-figma-text-color: #495057;
$tb-figma-timepicker-hover: #f6f6f6;
$tb-figma-white-background: #ffffff;
$tb-figma-black: #000000;
$tb-figma-black-4: rgba(0, 0, 0, 0.04);
$tb-figma-black-8: rgba(0, 0, 0, 0.08);
$tb-figma-primary-button-active-bg: #ec7859;
$tb-figma-primary-button-hovered-bg: #f57d5d;
$tb-figma-primary-button-active-border: #ec7859;
$tb-figma-primary-button-border: #f87f5f;
$tb-figma-primary-button-hover-border: #f57d5d;
$tb-figma-secondary-button-active-bg: #c7cdd5;
$tb-figma-secondary-button-bg: #e4e7eb;
$tb-figma-secondary-button-hovered-bg: #ced3db;
$tb-figma-secondary-button-active-border: #bfc6d0;
$tb-figma-secondary-button-border: #e4e7eb;
$tb-figma-secondary-button-focus-border: rgba(228, 231, 235, 0.5);
$tb-figma-secondary-button-hover-border: #c7cdd5;
$tb-figma-calendar-body-overlay: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #ffffff 100%);
$tb-figma-calendar-navigation-overlay: linear-gradient(180deg, #f8f9fa 0%, rgba(255, 255, 255, 0.0001) 52.08%, #f8f9fa 100%);
$tb-figma-timepicker-navigation-overlay: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.0001) 52.08%, #ffffff 100%);
$tb-figma-gray-900-33: rgba(33, 37, 41, 0.33);
$tb-figma-grid-cell-alternate: rgba(73, 65, 154, 0.05);
$tb-figma-grid-sorted-rows-and-columns: rgba(73, 65, 154, 0.03);
$tb-figma-error: #dc3545;
$tb-figma-error-16: rgba(220, 53, 69, 0.16);
$tb-figma-error-24: rgba(220, 53, 69, 0.24);
$tb-figma-error-30: rgba(220, 53, 69, 0.3);
$tb-figma-error-50: rgba(220, 53, 69, 0.5);
$tb-figma-error-8: rgba(220, 53, 69, 0.08);
$tb-figma-info: #eeecda;
$tb-figma-info-16: rgba(238, 236, 218, 0.16);
$tb-figma-info-24: rgba(238, 236, 218, 0.24);
$tb-figma-info-30: rgba(238, 236, 218, 0.3);
$tb-figma-info-50: rgba(238, 236, 218, 0.5);
$tb-figma-info-8: rgba(238, 236, 218, 0.08);
$tb-figma-success: #549c8b;
$tb-figma-success-16: rgba(84, 156, 139, 0.16);
$tb-figma-success-24: rgba(84, 156, 139, 0.24);
$tb-figma-success-30: rgba(84, 156, 139, 0.3);
$tb-figma-success-50: rgba(25, 135, 84, 0.5);
$tb-figma-success-8: rgba(84, 156, 139, 0.08);
$tb-figma-warning: #f7ce3a;
$tb-figma-warning-16: rgba(247, 206, 58, 0.16);
$tb-figma-warning-24: rgba(247, 206, 58, 0.24);
$tb-figma-warning-30: rgba(247, 206, 58, 0.3);
$tb-figma-warning-50: rgba(255, 193, 7, 0.5);
$tb-figma-warning-8: rgba(247, 206, 58, 0.08);
$tb-figma-primary-gradient: linear-gradient(180deg, #49419a 0%, #6f6aa5 100%);
$tb-figma-primarycolor-12: rgba(73, 65, 154, 0.12);
$tb-figma-primarycolor-16: rgba(73, 65, 154, 0.16);
$tb-figma-primarycolor-20: rgba(73, 65, 154, 0.2);
$tb-figma-primarycolor-25: rgba(73, 65, 154, 0.25);
$tb-figma-primarycolor-50: rgba(73, 65, 154, 0.5);
$tb-figma-primarycolor-8: rgba(73, 65, 154, 0.08);
$tb-figma-base-text-12: rgba(33, 37, 41, 0.12);
$tb-figma-base-text-16: rgba(33, 37, 41, 0.16);
$tb-figma-base-text-20: rgba(33, 37, 41, 0.2);
$tb-figma-base-text-24: rgba(33, 37, 41, 0.24);
$tb-figma-base-text-30: rgba(33, 37, 41, 0.3);
$tb-figma-base-text-50: rgba(33, 37, 41, 0.5);
$tb-figma-base-text-60: rgba(33, 37, 41, 0.6);
$tb-figma-base-text-65: rgba(33, 37, 41, 0.65);
$tb-figma-base-text-8: rgba(33, 37, 41, 0.08);
$tb-figma-series-a: #473f98;
$tb-figma-series-a-darken-50: linear-gradient(180deg, rgba(71, 63, 152, 0.5) 0%, rgba(71, 63, 152, 0.5) 100%);
$tb-figma-series-a-darken-75: linear-gradient(180deg, rgba(71, 63, 152, 0.75) 0%, rgba(71, 63, 152, 0.75) 100%);
$tb-figma-series-a-lighten-50: linear-gradient(180deg, rgba(71, 63, 152, 0.5) 0%, rgba(71, 63, 152, 0.5) 100%);
$tb-figma-series-a-lighten-75: linear-gradient(180deg, rgba(71, 63, 152, 0.75) 0%, rgba(71, 63, 152, 0.75) 100%);
$tb-figma-series-b: #3a7a7c;
$tb-figma-series-b-darken-50-i: linear-gradient(180deg, rgba(58, 122, 124, 0.5) 0%, rgba(58, 122, 124, 0.5) 100%);
$tb-figma-series-b-darken-75: linear-gradient(180deg, rgba(58, 122, 124, 0.75) 0%, rgba(58, 122, 124, 0.75) 100%);
$tb-figma-series-b-lighten-50: linear-gradient(180deg, rgba(58, 122, 124, 0.5) 0%, rgba(58, 122, 124, 0.5) 100%);
$tb-figma-series-b-lighten-75: linear-gradient(180deg, rgba(58, 122, 124, 0.75) 0%, rgba(58, 122, 124, 0.75) 100%);
$tb-figma-series-c: #fa7f5f;
$tb-figma-series-c-darken-50: linear-gradient(180deg, rgba(250, 127, 95, 0.5) 0%, rgba(250, 127, 95, 0.5) 100%);
$tb-figma-series-c-darken-75: linear-gradient(180deg, rgba(250, 127, 95, 0.75) 0%, rgba(250, 127, 95, 0.75) 100%);
$tb-figma-series-c-lighten-50: linear-gradient(180deg, rgba(250, 127, 95, 0.5) 0%, rgba(250, 127, 95, 0.5) 100%);
$tb-figma-series-c-lighten-75: linear-gradient(180deg, rgba(250, 127, 95, 0.75) 0%, rgba(250, 127, 95, 0.75) 100%);
$tb-figma-series-d: #dc3545;
$tb-figma-series-d-darken-50: linear-gradient(180deg, rgba(220, 53, 69, 0.5) 0%, rgba(220, 53, 69, 0.5) 100%);
$tb-figma-series-d-darken-75: linear-gradient(180deg, rgba(220, 53, 69, 0.75) 0%, rgba(220, 53, 69, 0.75) 100%);
$tb-figma-series-d-lighten-50: linear-gradient(180deg, rgba(220, 53, 69, 0.5) 0%, rgba(220, 53, 69, 0.5) 100%);
$tb-figma-series-d-lighten-75: linear-gradient(180deg, rgba(220, 53, 69, 0.75) 0%, rgba(220, 53, 69, 0.75) 100%);
$tb-figma-series-e: #e4e4c8;
$tb-figma-series-e-darken-50: linear-gradient(180deg, rgba(228, 228, 200, 0.5) 0%, rgba(228, 228, 200, 0.5) 100%);
$tb-figma-series-e-darken-75: linear-gradient(180deg, rgba(228, 228, 200, 0.75) 0%, rgba(228, 228, 200, 0.75) 100%);
$tb-figma-series-e-lighten-50: linear-gradient(180deg, rgba(228, 228, 200, 0.5) 0%, rgba(228, 228, 200, 0.5) 100%);
$tb-figma-series-e-lighten-75: linear-gradient(180deg, rgba(228, 228, 200, 0.75) 0%, rgba(228, 228, 200, 0.75) 100%);
$tb-figma-series-f: #78a9cc;
$tb-figma-series-f-darken-50: linear-gradient(180deg, rgba(120, 169, 204, 0.5) 0%, rgba(120, 169, 204, 0.5) 100%);
$tb-figma-series-f-darken-75: linear-gradient(180deg, rgba(120, 169, 204, 0.75) 0%, rgba(120, 169, 204, 0.75) 100%);
$tb-figma-series-f-lighten-50: linear-gradient(180deg, rgba(120, 169, 204, 0.5) 0%, rgba(120, 169, 204, 0.5) 100%);
$tb-figma-series-f-lighten-75: linear-gradient(180deg, rgba(120, 169, 204, 0.75) 0%, rgba(120, 169, 204, 0.75) 100%);
$tb-figma-colors-figma-helper-figma-bg: #f8f9fa;
$tb-figma-colors-figma-helper-figma-border: rgba(33, 37, 41, 0.2);
$tb-figma-colors-figma-helper-figma-label: #596169;
$tb-figma-colors-gradients-calendar-body-overlay: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #ffffff 100%);
$tb-figma-colors-gradients-calendar-navigation-overlay: linear-gradient(180deg, #f8f9fa 0%, rgba(255, 255, 255, 0.0001) 25%, rgba(254, 254, 255, 0.099) 75%, #f8f9fa 100%);
$tb-figma-colors-gradients-timepicker-hover: rgba(0, 0, 0, 0.02);
$tb-figma-colors-gradients-timepicker-navigation-overlay: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.0001) 50%, #ffffff 100%);
$tb-kendo-common-chip-gradient: linear-gradient(to right, #8E2DE2, #E100FF);
$tb-kendo-common-chip-solid: #4A00E0;
$tb-kendo-body-bg: $tb-figma-bg-color;
$tb-kendo-hover-text: $tb-kendo-base-text;
$tb-kendo-hover-border: $tb-kendo-hover-bg;
$tb-kendo-button-bg: $tb-figma-primary-button-bg;
$tb-kendo-button-border: $tb-kendo-button-bg;
$tb-kendo-card-header-border: $tb-kendo-card-border;
$tb-kendo-card-footer-bg: $tb-kendo-card-header-bg;
$tb-kendo-card-footer-text: $tb-kendo-card-header-text;
$tb-kendo-card-footer-border: $tb-kendo-card-header-border;
$tb-kendo-series-1: $tb-kendo-series-a;
$tb-kendo-checkbox-checked-bg: $tb-kendo-color-primary;
$tb-kendo-checkbox-checked-border: $tb-kendo-checkbox-checked-bg;
$tb-kendo-checkbox-indeterminate-bg: $tb-kendo-checkbox-checked-bg;
$tb-kendo-checkbox-indeterminate-text: $tb-kendo-checkbox-checked-text;
$tb-kendo-checkbox-indeterminate-border: $tb-kendo-checkbox-checked-border;
$tb-kendo-checkbox-focus-checked-border: $tb-kendo-checkbox-checked-border;
$tb-kendo-checkbox-ripple-bg: $tb-kendo-checkbox-checked-bg;
$tb-kendo-chip-solid-bg: $tb-figma-base-bg;
$tb-kendo-chip-solid-text: $tb-kendo-button-text;
$tb-kendo-chip-solid-hover-bg: $tb-kendo-button-hover-bg;
$tb-kendo-chip-solid-selected-bg: $tb-kendo-button-active-bg;
$tb-kendo-chip-outline-text: $tb-kendo-chip-solid-text;
$tb-kendo-chip-outline-border: $tb-kendo-chip-outline-text;
$tb-kendo-chip-outline-hover-bg: $tb-kendo-chip-outline-text;
$tb-kendo-chip-outline-selected-bg: $tb-kendo-chip-outline-hover-bg;
$tb-kendo-chip-outline-selected-text: $tb-kendo-chip-outline-hover-text;
$tb-kendo-color-gradient-focus-border: $tb-kendo-hover-border;
$tb-kendo-color-info: $tb-figma-colors-secondary-button-selected;
$tb-kendo-color-editor-focus-border: $tb-kendo-hover-border;
$tb-kendo-dock-manager-border: $tb-kendo-base-border;
$tb-kendo-dock-manager-pane-header-bg: $tb-kendo-component-bg;
$tb-kendo-dock-manager-unpinned-container-bg: $tb-kendo-component-bg;
$tb-kendo-dock-indicator-bg: $tb-kendo-component-bg;
$tb-kendo-dock-indicator-text: $tb-kendo-color-primary;
$tb-kendo-dock-indicator-outline: $tb-kendo-dock-indicator-text;
$tb-kendo-dock-indicator-hover-bg: $tb-kendo-color-primary;
$tb-kendo-dock-manager-dock-preview-border: $tb-kendo-color-primary;
$tb-kendo-dropzone-text: $tb-kendo-component-text;
$tb-kendo-dropzone-border: $tb-kendo-component-border;
$tb-kendo-dropzone-icon-hover-text: $tb-kendo-color-primary;
$tb-kendo-editor-selected-text: $tb-kendo-color-primary-contrast;
$tb-kendo-editor-selected-bg: $tb-kendo-color-primary;
$tb-kendo-expander-text: $tb-kendo-component-text;
$tb-kendo-expander-bg: $tb-kendo-component-bg;
$tb-kendo-expander-border: $tb-kendo-component-border;
$tb-kendo-expander-header-text: $tb-kendo-expander-text;
$tb-kendo-expander-title-text: $tb-kendo-color-primary;
$tb-kendo-filter-preview-field-text: $tb-kendo-color-primary;
$tb-kendo-fab-item-text: $tb-kendo-component-text;
$tb-kendo-fab-item-bg: $tb-kendo-component-bg;
$tb-kendo-fab-item-border: $tb-kendo-component-border;
$tb-kendo-fab-item-icon-text: $tb-kendo-button-text;
$tb-kendo-fab-item-icon-bg: $tb-kendo-button-bg;
$tb-kendo-fab-item-icon-border: $tb-kendo-button-border;
$tb-kendo-fab-item-outline-color: $tb-kendo-button-bg;
$tb-kendo-form-legend-border-color: $tb-kendo-component-border;
$tb-kendo-form-separator-border-color: $tb-kendo-form-legend-border-color;
$tb-kendo-grid-selection-aggregates-bg: $tb-kendo-grid-header-bg;
$tb-kendo-grid-selection-aggregates-text: $tb-kendo-grid-text;
$tb-kendo-grid-selection-aggregates-border: $tb-kendo-grid-border;
$tb-kendo-grid-row-resizer-active-bg: $tb-kendo-color-primary;
$tb-kendo-input-outline-text: $tb-kendo-base-text;
$tb-kendo-input-outline-focus-border: $tb-kendo-input-focus-border;
$tb-kendo-input-flat-text: $tb-kendo-input-text;
$tb-kendo-input-flat-border: $tb-kendo-input-border;
$tb-kendo-input-flat-focus-border: $tb-kendo-input-focus-border;
$tb-kendo-input-separator-text: $tb-kendo-component-border;
$tb-kendo-list-bg: $tb-kendo-component-bg;
$tb-kendo-list-text: $tb-kendo-component-text;
$tb-kendo-list-border: $tb-kendo-component-border;
$tb-kendo-list-item-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-list-item-hover-text: $tb-kendo-hover-text;
$tb-kendo-list-item-selected-bg: $tb-kendo-selected-bg;
$tb-kendo-list-item-selected-text: $tb-kendo-selected-text;
$tb-kendo-listbox-text: $tb-kendo-component-text;
$tb-kendo-listbox-bg: $tb-kendo-component-bg;
$tb-kendo-listbox-border: $tb-kendo-component-border;
$tb-kendo-listview-text: $tb-kendo-component-text;
$tb-kendo-listview-bg: $tb-kendo-component-bg;
$tb-kendo-listview-border: $tb-kendo-component-border;
$tb-kendo-loader-container-panel-border-color: $tb-kendo-component-border;
$tb-kendo-loading-bg: $tb-kendo-component-bg;
$tb-kendo-menu-popup-item-hover-bg: $tb-kendo-list-item-hover-bg;
$tb-kendo-menu-popup-item-hover-text: $tb-kendo-list-item-hover-text;
$tb-kendo-menu-popup-item-expanded-bg: $tb-kendo-list-item-selected-bg;
$tb-kendo-menu-popup-item-expanded-text: $tb-kendo-list-item-selected-text;
$tb-kendo-notification-bg: $tb-kendo-component-bg;
$tb-kendo-notification-text: $tb-kendo-component-text;
$tb-kendo-notification-border: $tb-kendo-component-border;
$tb-kendo-picker-bg: $tb-kendo-button-bg;
$tb-kendo-picker-text: $tb-kendo-button-text;
$tb-kendo-picker-border: $tb-kendo-button-border;
$tb-kendo-picker-hover-bg: $tb-kendo-button-hover-bg;
$tb-kendo-picker-hover-border: $tb-kendo-button-hover-border;
$tb-kendo-picker-focus-bg: $tb-kendo-button-active-bg;
$tb-kendo-picker-focus-border: $tb-kendo-button-focus-border;
$tb-kendo-picker-outline-text: $tb-kendo-button-text;
$tb-kendo-picker-outline-hover-bg: $tb-kendo-picker-outline-text;
$tb-kendo-picker-outline-hover-border: $tb-kendo-picker-outline-hover-bg;
$tb-kendo-picker-flat-text: $tb-kendo-button-text;
$tb-kendo-picker-flat-border: $tb-kendo-button-border;
$tb-kendo-popover-text: $tb-kendo-card-text;
$tb-kendo-popover-bg: $tb-kendo-card-bg;
$tb-kendo-popover-border: $tb-kendo-card-border;
$tb-kendo-popup-bg: $tb-kendo-component-bg;
$tb-kendo-popup-text: $tb-kendo-component-text;
$tb-kendo-popup-border: $tb-kendo-component-border;
$tb-kendo-progressbar-value-bg: $tb-kendo-selected-bg;
$tb-kendo-progressbar-value-text: $tb-kendo-selected-text;
$tb-kendo-progressbar-indeterminate-bg: $tb-kendo-progressbar-bg;
$tb-kendo-progressbar-indeterminate-text: $tb-kendo-progressbar-text;
$tb-kendo-circular-progressbar-arc-stroke: $tb-kendo-color-primary;
$tb-kendo-radio-bg: $tb-kendo-checkbox-bg;
$tb-kendo-radio-border: $tb-kendo-checkbox-border;
$tb-kendo-radio-checked-bg: $tb-kendo-checkbox-checked-bg;
$tb-kendo-radio-checked-text: $tb-kendo-checkbox-checked-text;
$tb-kendo-radio-checked-border: $tb-kendo-checkbox-checked-border;
$tb-kendo-radio-focus-border: $tb-kendo-checkbox-focus-border;
$tb-kendo-radio-focus-checked-border: $tb-kendo-checkbox-focus-checked-border;
$tb-kendo-radio-invalid-text: $tb-kendo-checkbox-invalid-text;
$tb-kendo-radio-invalid-border: $tb-kendo-checkbox-invalid-border;
$tb-kendo-radio-ripple-bg: $tb-kendo-radio-checked-bg;
$tb-kendo-rating-icon-selected-text: $tb-kendo-selected-bg;
$tb-kendo-rating-icon-hover-text: $tb-kendo-selected-bg;
$tb-kendo-rating-icon-focus-text: $tb-kendo-selected-bg;
$tb-kendo-scrollview-text: $tb-kendo-component-text;
$tb-kendo-scrollview-bg: $tb-kendo-component-bg;
$tb-kendo-scrollview-border: $tb-kendo-component-border;
$tb-kendo-scrollview-pagebutton-bg: $tb-kendo-button-bg;
$tb-kendo-scrollview-pagebutton-border: $tb-kendo-button-border;
$tb-kendo-scrollview-pagebutton-primary-bg: $tb-kendo-color-primary;
$tb-kendo-scrollview-pagebutton-primary-border: $tb-kendo-color-primary;
$tb-kendo-switch-off-track-bg: $tb-kendo-component-bg;
$tb-kendo-switch-on-track-bg: $tb-kendo-color-primary;
$tb-kendo-switch-on-track-border: $tb-kendo-switch-on-track-bg;
$tb-kendo-table-bg: $tb-kendo-component-bg;
$tb-kendo-table-text: $tb-kendo-component-text;
$tb-kendo-table-border: $tb-kendo-component-border;
$tb-kendo-table-footer-bg: $tb-kendo-table-header-bg;
$tb-kendo-table-footer-text: $tb-kendo-table-header-text;
$tb-kendo-table-footer-border: $tb-kendo-table-header-border;
$tb-kendo-table-group-row-bg: $tb-kendo-table-header-bg;
$tb-kendo-table-group-row-text: $tb-kendo-table-header-text;
$tb-kendo-table-group-row-border: $tb-kendo-table-header-border;
$tb-kendo-table-selected-text: $tb-kendo-table-text;
$tb-kendo-tabstrip-text: $tb-kendo-component-text;
$tb-kendo-tabstrip-border: $tb-kendo-component-border;
$tb-kendo-tabstrip-item-selected-bg: $tb-kendo-component-bg;
$tb-kendo-tabstrip-item-selected-text: $tb-kendo-component-text;
$tb-kendo-tabstrip-item-selected-border: $tb-kendo-component-border;
$tb-kendo-tabstrip-content-bg: $tb-kendo-component-bg;
$tb-kendo-tabstrip-content-text: $tb-kendo-component-text;
$tb-kendo-tabstrip-content-border: $tb-kendo-component-border;
$tb-kendo-tabstrip-content-focus-border: $tb-kendo-component-text;
$tb-kendo-tile-layout-hint-border: $tb-kendo-component-border;
$tb-kendo-tooltip-border: $tb-kendo-tooltip-bg;
$tb-kendo-treeview-text: $tb-kendo-component-text;
$tb-kendo-treeview-item-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-treeview-item-hover-text: $tb-kendo-hover-text;
$tb-kendo-treeview-item-hover-border: $tb-kendo-treeview-item-hover-bg;
$tb-kendo-treeview-item-selected-bg: $tb-kendo-selected-bg;
$tb-kendo-treeview-item-selected-text: $tb-kendo-selected-text;
$tb-kendo-treeview-item-selected-border: $tb-kendo-treeview-item-selected-bg;
$tb-kendo-upload-text: $tb-kendo-component-text;
$tb-kendo-upload-bg: $tb-kendo-component-bg;
$tb-kendo-upload-border: $tb-kendo-component-border;
$tb-kendo-upload-dropzone-border: $tb-kendo-upload-border;
$tb-kendo-upload-progress-bg: $tb-kendo-color-info;
$tb-kendo-upload-success-text: $tb-kendo-color-success;
$tb-kendo-upload-success-bg: $tb-kendo-color-success;
$tb-kendo-upload-error-text: $tb-kendo-color-error;
$tb-kendo-upload-error-bg: $tb-kendo-color-error;
$tb-kendo-window-bg: $tb-kendo-component-bg;
$tb-kendo-window-text: $tb-kendo-component-text;
$tb-kendo-window-border: $tb-kendo-component-border;
$tb-kendo-window-titlebar-border: $tb-kendo-component-border;

$tb-kendo-appbar-box-shadow: 0px 6px 13px 0 rgba(0, 0, 0, 0.125);
$tb-kendo-appbar-bottom-box-shadow: 0px 6px 13px 0 rgba(0, 0, 0, 0.125);
$tb-kendo-bottom-nav-shadow: 0px 6px 13px 0 rgba(0, 0, 0, 0.125);
$tb-kendo-card-focus-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
$tb-kendo-fab-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
$tb-kendo-fab-disabled-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
$tb-kendo-popup-shadow: 0px 6px 13px 0 rgba(0, 0, 0, 0.125);
$tb-kendo-tooltip-shadow: 0px 2px 7px 0 rgba(0, 0, 0, 0.075);

$kendo-is-dark-theme: $tb-kendo-is-dark-theme;
$kendo-border-radius: $tb-kendo-border-radius;
$kendo-appbar-padding-x: $tb-kendo-appbar-padding-x;
$kendo-appbar-padding-y: $tb-kendo-appbar-padding-y;
$kendo-appbar-border-width: $tb-kendo-appbar-border-width;
$kendo-appbar-font-size: $tb-kendo-appbar-font-size;
$kendo-appbar-gap: $tb-kendo-appbar-gap;
$kendo-avatar-border-width: $tb-kendo-avatar-border-width;
$kendo-avatar-font-size: $tb-kendo-avatar-font-size;
$kendo-badge-border-width: $tb-kendo-badge-border-width;
$kendo-bottom-nav-padding-x: $tb-kendo-bottom-nav-padding-x;
$kendo-bottom-nav-font-size: $tb-kendo-bottom-nav-font-size;
$kendo-bottom-nav-letter-spacing: $tb-kendo-bottom-nav-letter-spacing;
$kendo-bottom-nav-item-padding-x: $tb-kendo-bottom-nav-item-padding-x;
$kendo-bottom-nav-item-min-width: $tb-kendo-bottom-nav-item-min-width;
$kendo-breadcrumb-border-width: $tb-kendo-breadcrumb-border-width;
$kendo-breadcrumb-font-size: $tb-kendo-breadcrumb-font-size;
$kendo-breadcrumb-link-padding-x: $tb-kendo-breadcrumb-link-padding-x;
$kendo-breadcrumb-link-padding-y: $tb-kendo-breadcrumb-link-padding-y;
$kendo-breadcrumb-link-border-radius: $tb-kendo-breadcrumb-link-border-radius;
$kendo-breadcrumb-icon-link-padding-y: $tb-kendo-breadcrumb-icon-link-padding-y;
$kendo-breadcrumb-link-icon-spacing: $tb-kendo-breadcrumb-link-icon-spacing;
$kendo-button-border-width: $tb-kendo-button-border-width;
$kendo-button-padding-x: $tb-kendo-button-padding-x;
$kendo-button-padding-y: $tb-kendo-button-padding-y;
$kendo-card-padding-x: $tb-kendo-card-padding-x;
$kendo-card-padding-y: $tb-kendo-card-padding-y;
$kendo-card-border-width: $tb-kendo-card-border-width;
$kendo-card-border-radius: $tb-kendo-card-border-radius;
$kendo-card-font-size: $tb-kendo-card-font-size;
$kendo-card-deck-gap: $tb-kendo-card-deck-gap;
$kendo-card-title-margin-bottom: $tb-kendo-card-title-margin-bottom;
$kendo-card-title-font-size: $tb-kendo-card-title-font-size;
$kendo-card-subtitle-margin-bottom: $tb-kendo-card-subtitle-margin-bottom;
$kendo-card-subtitle-font-size: $tb-kendo-card-subtitle-font-size;
$kendo-card-img-max-width: $tb-kendo-card-img-max-width;
$kendo-card-avatar-size: $tb-kendo-card-avatar-size;
$kendo-card-actions-padding-x: $tb-kendo-card-actions-padding-x;
$kendo-card-actions-padding-y: $tb-kendo-card-actions-padding-y;
$kendo-card-actions-gap: $tb-kendo-card-actions-gap;
$kendo-card-deck-scroll-button-radius: $tb-kendo-card-deck-scroll-button-radius;
$kendo-card-deck-scroll-button-offset: $tb-kendo-card-deck-scroll-button-offset;
$kendo-checkbox-border-width: $tb-kendo-checkbox-border-width;
$kendo-checkbox-label-margin-x: $tb-kendo-checkbox-label-margin-x;
$kendo-checkbox-list-spacing: $tb-kendo-checkbox-list-spacing;
$kendo-checkbox-list-item-padding-x: $tb-kendo-checkbox-list-item-padding-x;
$kendo-checkbox-list-item-padding-y: $tb-kendo-checkbox-list-item-padding-y;
$kendo-chip-border-width: $tb-kendo-chip-border-width;
$kendo-chip-spacing: $tb-kendo-chip-spacing;
$kendo-chip-padding-x: $tb-kendo-chip-padding-x;
$kendo-chip-padding-y: $tb-kendo-chip-padding-y;
$kendo-chip-font-size: $tb-kendo-chip-font-size;
$kendo-color-gradient-spacer: $tb-kendo-color-gradient-spacer;
$kendo-color-gradient-width: $tb-kendo-color-gradient-width;
$kendo-color-gradient-border-width: $tb-kendo-color-gradient-border-width;
$kendo-color-gradient-border-radius: $tb-kendo-color-gradient-border-radius;
$kendo-color-gradient-font-size: $tb-kendo-color-gradient-font-size;
$kendo-color-gradient-canvas-border-radius: $tb-kendo-color-gradient-canvas-border-radius;
$kendo-color-gradient-canvas-gap: $tb-kendo-color-gradient-canvas-gap;
$kendo-color-gradient-canvas-rectangle-height: $tb-kendo-color-gradient-canvas-rectangle-height;
$kendo-color-gradient-slider-track-size: $tb-kendo-color-gradient-slider-track-size;
$kendo-color-gradient-slider-border-radius: $tb-kendo-color-gradient-slider-border-radius;
$kendo-color-gradient-slider-draghandle-border-width: $tb-kendo-color-gradient-slider-draghandle-border-width;
$kendo-color-gradient-slider-vertical-size: $tb-kendo-color-gradient-slider-vertical-size;
$kendo-color-gradient-draghandle-width: $tb-kendo-color-gradient-draghandle-width;
$kendo-color-gradient-draghandle-height: $tb-kendo-color-gradient-draghandle-height;
$kendo-color-gradient-draghandle-border-width: $tb-kendo-color-gradient-draghandle-border-width;
$kendo-color-gradient-draghandle-outline-width: $tb-kendo-color-gradient-draghandle-outline-width;
$kendo-color-gradient-canvas-draghandle-margin-y: $tb-kendo-color-gradient-canvas-draghandle-margin-y;
$kendo-color-gradient-canvas-draghandle-margin-x: $tb-kendo-color-gradient-canvas-draghandle-margin-x;
$kendo-color-gradient-input-width: $tb-kendo-color-gradient-input-width;
$kendo-color-gradient-input-gap: $tb-kendo-color-gradient-input-gap;
$kendo-color-gradient-input-label-gap: $tb-kendo-color-gradient-input-label-gap;
$kendo-color-gradient-contrast-spacer: $tb-kendo-color-gradient-contrast-spacer;
$kendo-color-editor-spacer: $tb-kendo-color-editor-spacer;
$kendo-color-editor-min-width: $tb-kendo-color-editor-min-width;
$kendo-color-editor-border-width: $tb-kendo-color-editor-border-width;
$kendo-color-editor-border-radius: $tb-kendo-color-editor-border-radius;
$kendo-color-editor-font-size: $tb-kendo-color-editor-font-size;
$kendo-color-editor-header-actions-gap: $tb-kendo-color-editor-header-actions-gap;
$kendo-color-editor-color-preview-width: $tb-kendo-color-editor-color-preview-width;
$kendo-color-editor-color-preview-height: $tb-kendo-color-editor-color-preview-height;
$kendo-color-editor-preview-gap: $tb-kendo-color-editor-preview-gap;
$kendo-color-editor-color-gradient-focus-outline: $tb-kendo-color-editor-color-gradient-focus-outline;
$kendo-color-palette-font-size: $tb-kendo-color-palette-font-size;
$kendo-color-palette-tile-width: $tb-kendo-color-palette-tile-width;
$kendo-dialog-buttongroup-padding-x: $tb-kendo-dialog-buttongroup-padding-x;
$kendo-dialog-buttongroup-padding-y: $tb-kendo-dialog-buttongroup-padding-y;
$kendo-dialog-buttongroup-border-width: $tb-kendo-dialog-buttongroup-border-width;
$kendo-dialog-button-spacing: $tb-kendo-dialog-button-spacing;
$kendo-dock-manager-border-width: $tb-kendo-dock-manager-border-width;
$kendo-dock-manager-pane-header-padding-y: $tb-kendo-dock-manager-pane-header-padding-y;
$kendo-dock-manager-pane-header-padding-x: $tb-kendo-dock-manager-pane-header-padding-x;
$kendo-dock-manager-pane-title-font-size: $tb-kendo-dock-manager-pane-title-font-size;
$kendo-dock-manager-pane-content-padding-y: $tb-kendo-dock-manager-pane-content-padding-y;
$kendo-dock-manager-tabbed-pane-padding-y: $tb-kendo-dock-manager-tabbed-pane-padding-y;
$kendo-dock-manager-unpinned-container-width: $tb-kendo-dock-manager-unpinned-container-width;
$kendo-dock-indicator-padding: $tb-kendo-dock-indicator-padding;
$kendo-dock-indicator-outline-width: $tb-kendo-dock-indicator-outline-width;
$kendo-dock-manager-dock-preview-border-width: $tb-kendo-dock-manager-dock-preview-border-width;
$kendo-dock-manager-dock-preview-border-radius: $tb-kendo-dock-manager-dock-preview-border-radius;
$kendo-dropdowntree-popup-padding-x: $tb-kendo-dropdowntree-popup-padding-x;
$kendo-dropdowntree-popup-padding-y: $tb-kendo-dropdowntree-popup-padding-y;
$kendo-dropzone-padding-x: $tb-kendo-dropzone-padding-x;
$kendo-dropzone-padding-y: $tb-kendo-dropzone-padding-y;
$kendo-dropzone-border-width: $tb-kendo-dropzone-border-width;
$kendo-dropzone-min-height: $tb-kendo-dropzone-min-height;
$kendo-dropzone-font-size: $tb-kendo-dropzone-font-size;
$kendo-dropzone-icon-spacing: $tb-kendo-dropzone-icon-spacing;
$kendo-dropzone-hint-spacing: $tb-kendo-dropzone-hint-spacing;
$kendo-dropzone-note-font-size: $tb-kendo-dropzone-note-font-size;
$kendo-editor-border-width: $tb-kendo-editor-border-width;
$kendo-editor-font-size: $tb-kendo-editor-font-size;
$kendo-editor-resize-handle-size: $tb-kendo-editor-resize-handle-size;
$kendo-editor-resize-handle-border-width: $tb-kendo-editor-resize-handle-border-width;
$kendo-editor-selectednode-outline-width: $tb-kendo-editor-selectednode-outline-width;
$kendo-expander-spacing-y: $tb-kendo-expander-spacing-y;
$kendo-expander-border-width: $tb-kendo-expander-border-width;
$kendo-expander-font-size: $tb-kendo-expander-font-size;
$kendo-expander-header-padding-x: $tb-kendo-expander-header-padding-x;
$kendo-expander-header-padding-y: $tb-kendo-expander-header-padding-y;
$kendo-expander-indicator-margin-x: $tb-kendo-expander-indicator-margin-x;
$kendo-expander-content-padding-x: $tb-kendo-expander-content-padding-x;
$kendo-expander-content-padding-y: $tb-kendo-expander-content-padding-y;
$kendo-filter-padding-x: $tb-kendo-filter-padding-x;
$kendo-filter-padding-y: $tb-kendo-filter-padding-y;
$kendo-filter-line-size: $tb-kendo-filter-line-size;
$kendo-fab-border-width: $tb-kendo-fab-border-width;
$kendo-fab-font-size: $tb-kendo-fab-font-size;
$kendo-fab-padding-x: $tb-kendo-fab-padding-x;
$kendo-fab-icon-padding-x: $tb-kendo-fab-icon-padding-x;
$kendo-fab-icon-spacing: $tb-kendo-fab-icon-spacing;
$kendo-fab-items-padding-x: $tb-kendo-fab-items-padding-x;
$kendo-fab-items-padding-y: $tb-kendo-fab-items-padding-y;
$kendo-fab-item-text-padding-x: $tb-kendo-fab-item-text-padding-x;
$kendo-fab-item-text-border-width: $tb-kendo-fab-item-text-border-width;
$kendo-fab-item-text-border-radius: $tb-kendo-fab-item-text-border-radius;
$kendo-fab-item-text-font-size: $tb-kendo-fab-item-text-font-size;
$kendo-fab-item-text-line-height: $tb-kendo-fab-item-text-line-height;
$kendo-fab-item-icon-padding-x: $tb-kendo-fab-item-icon-padding-x;
$kendo-fab-outline-width: $tb-kendo-fab-outline-width;
$kendo-fab-item-outline-width: $tb-kendo-fab-item-outline-width;
$kendo-floating-label-font-size: $tb-kendo-floating-label-font-size;
$kendo-form-spacer: $tb-kendo-form-spacer;
$kendo-form-font-size: $tb-kendo-form-font-size;
$kendo-form-fieldset-padding: $tb-kendo-form-fieldset-padding;
$kendo-form-legend-padding: $tb-kendo-form-legend-padding;
$kendo-form-legend-font-size: $tb-kendo-form-legend-font-size;
$kendo-form-label-margin-bottom: $tb-kendo-form-label-margin-bottom;
$kendo-form-hint-font-size: $tb-kendo-form-hint-font-size;
$kendo-form-hint-margin-top: $tb-kendo-form-hint-margin-top;
$kendo-horizontal-form-label-padding-top: $tb-kendo-horizontal-form-label-padding-top;
$kendo-horizontal-form-label-margin-x: $tb-kendo-horizontal-form-label-margin-x;
$kendo-label-optional-margin-x: $tb-kendo-label-optional-margin-x;
$kendo-label-optional-font-size: $tb-kendo-label-optional-font-size;
$kendo-fieldset-margin: $tb-kendo-fieldset-margin;
$kendo-grid-padding-x: $tb-kendo-grid-padding-x;
$kendo-grid-padding-y: $tb-kendo-grid-padding-y;
$kendo-grid-edit-cell-padding-y: $tb-kendo-grid-edit-cell-padding-y;
$kendo-grid-selection-aggregates-border-width: $tb-kendo-grid-selection-aggregates-border-width;
$kendo-grid-selection-aggregates-spacing: $tb-kendo-grid-selection-aggregates-spacing;
$kendo-grid-row-resizer-height: $tb-kendo-grid-row-resizer-height;
$kendo-input-border-width: $tb-kendo-input-border-width;
$kendo-input-padding-x: $tb-kendo-input-padding-x;
$kendo-input-padding-y: $tb-kendo-input-padding-y;
$kendo-input-font-size: $tb-kendo-input-font-size;
$kendo-listbox-spacing: $tb-kendo-listbox-spacing;
$kendo-listbox-button-spacing: $tb-kendo-listbox-button-spacing;
$kendo-listbox-default-height: $tb-kendo-listbox-default-height;
$kendo-listbox-border-width: $tb-kendo-listbox-border-width;
$kendo-listbox-font-size: $tb-kendo-listbox-font-size;
$kendo-listbox-drop-hint-width: $tb-kendo-listbox-drop-hint-width;
$kendo-listview-padding-x: $tb-kendo-listview-padding-x;
$kendo-listview-padding-y: $tb-kendo-listview-padding-y;
$kendo-listview-border-width: $tb-kendo-listview-border-width;
$kendo-listview-font-size: $tb-kendo-listview-font-size;
$kendo-listview-item-padding-x: $tb-kendo-listview-item-padding-x;
$kendo-listview-item-padding-y: $tb-kendo-listview-item-padding-y;
$kendo-loader-container-panel-border-width: $tb-kendo-loader-container-panel-border-width;
$kendo-loader-container-panel-border-radius: $tb-kendo-loader-container-panel-border-radius;
$kendo-menu-popup-border-width: $tb-kendo-menu-popup-border-width;
$kendo-menu-popup-item-padding-x: $tb-kendo-menu-popup-item-padding-x;
$kendo-menu-popup-item-padding-y: $tb-kendo-menu-popup-item-padding-y;
$kendo-menu-popup-item-spacing: $tb-kendo-menu-popup-item-spacing;
$kendo-notification-group-gap: $tb-kendo-notification-group-gap;
$kendo-notification-padding-x: $tb-kendo-notification-padding-x;
$kendo-notification-padding-y: $tb-kendo-notification-padding-y;
$kendo-notification-border-width: $tb-kendo-notification-border-width;
$kendo-notification-border-radius: $tb-kendo-notification-border-radius;
$kendo-notification-font-size: $tb-kendo-notification-font-size;
$kendo-notification-icon-spacing: $tb-kendo-notification-icon-spacing;
$kendo-pager-border-width: $tb-kendo-pager-border-width;
$kendo-pager-font-size: $tb-kendo-pager-font-size;
$kendo-pager-item-border-width: $tb-kendo-pager-item-border-width;
$kendo-pager-item-border-radius: $tb-kendo-pager-item-border-radius;
$kendo-pager-item-spacing: $tb-kendo-pager-item-spacing;
$kendo-popup-border-width: $tb-kendo-popup-border-width;
$kendo-popup-border-radius: $tb-kendo-popup-border-radius;
$kendo-popup-font-size: $tb-kendo-popup-font-size;
$kendo-popup-content-padding-x: $tb-kendo-popup-content-padding-x;
$kendo-popup-content-padding-y: $tb-kendo-popup-content-padding-y;
$kendo-progressbar-height: $tb-kendo-progressbar-height;
$kendo-progressbar-border-width: $tb-kendo-progressbar-border-width;
$kendo-progressbar-font-size: $tb-kendo-progressbar-font-size;
$kendo-radio-border-width: $tb-kendo-radio-border-width;
$kendo-radio-label-margin-x: $tb-kendo-radio-label-margin-x;
$kendo-radio-list-spacing: $tb-kendo-radio-list-spacing;
$kendo-radio-list-item-padding-x: $tb-kendo-radio-list-item-padding-x;
$kendo-radio-list-item-padding-y: $tb-kendo-radio-list-item-padding-y;
$kendo-rating-font-size: $tb-kendo-rating-font-size;
$kendo-rating-container-margin-x: $tb-kendo-rating-container-margin-x;
$kendo-rating-item-padding-x: $tb-kendo-rating-item-padding-x;
$kendo-rating-item-padding-y: $tb-kendo-rating-item-padding-y;
$kendo-rating-label-margin-x: $tb-kendo-rating-label-margin-x;
$kendo-rating-label-margin-y: $tb-kendo-rating-label-margin-y;
$kendo-scrollview-border-width: $tb-kendo-scrollview-border-width;
$kendo-scrollview-font-size: $tb-kendo-scrollview-font-size;
$kendo-scrollview-pagebutton-size: $tb-kendo-scrollview-pagebutton-size;
$kendo-scrollview-pager-item-spacing: $tb-kendo-scrollview-pager-item-spacing;
$kendo-scrollview-pager-item-border-width: $tb-kendo-scrollview-pager-item-border-width;
$kendo-skeleton-text-border-radius: $tb-kendo-skeleton-text-border-radius;
$kendo-skeleton-circle-border-radius: $tb-kendo-skeleton-circle-border-radius;
$kendo-switch-track-border-width: $tb-kendo-switch-track-border-width;
$kendo-table-border-width: $tb-kendo-table-border-width;
$kendo-table-cell-vertical-border-width: $tb-kendo-table-cell-vertical-border-width;
$kendo-table-cell-horizontal-border-width: $tb-kendo-table-cell-horizontal-border-width;
$kendo-tabstrip-item-padding-x: $tb-kendo-tabstrip-item-padding-x;
$kendo-tabstrip-item-padding-y: $tb-kendo-tabstrip-item-padding-y;
$kendo-tabstrip-item-border-width: $tb-kendo-tabstrip-item-border-width;
$kendo-tabstrip-item-border-radius: $tb-kendo-tabstrip-item-border-radius;
$kendo-tabstrip-content-padding-x: $tb-kendo-tabstrip-content-padding-x;
$kendo-tabstrip-content-padding-y: $tb-kendo-tabstrip-content-padding-y;
$kendo-tabstrip-content-border-width: $tb-kendo-tabstrip-content-border-width;
$kendo-tile-layout-border-width: $tb-kendo-tile-layout-border-width;
$kendo-tile-layout-padding-x: $tb-kendo-tile-layout-padding-x;
$kendo-tile-layout-hint-border-width: $tb-kendo-tile-layout-hint-border-width;
$kendo-toolbar-border-width: $tb-kendo-toolbar-border-width;
$kendo-toolbar-font-size: $tb-kendo-toolbar-font-size;
$kendo-toolbar-flat-border-width: $tb-kendo-toolbar-flat-border-width;
$kendo-tooltip-padding-y: $tb-kendo-tooltip-padding-y;
$kendo-tooltip-padding-x: $tb-kendo-tooltip-padding-x;
$kendo-tooltip-border-width: $tb-kendo-tooltip-border-width;
$kendo-tooltip-border-radius: $tb-kendo-tooltip-border-radius;
$kendo-tooltip-font-size: $tb-kendo-tooltip-font-size;
$kendo-tooltip-title-font-size: $tb-kendo-tooltip-title-font-size;
$kendo-tooltip-callout-size: $tb-kendo-tooltip-callout-size;
$kendo-treeview-font-size: $tb-kendo-treeview-font-size;
$kendo-treeview-indent: $tb-kendo-treeview-indent;
$kendo-treeview-item-padding-x: $tb-kendo-treeview-item-padding-x;
$kendo-treeview-item-padding-y: $tb-kendo-treeview-item-padding-y;
$kendo-treeview-item-border-width: $tb-kendo-treeview-item-border-width;
$kendo-treeview-item-border-radius: $tb-kendo-treeview-item-border-radius;
$kendo-font-size: $tb-kendo-font-size;
$kendo-upload-border-width: $tb-kendo-upload-border-width;
$kendo-upload-font-size: $tb-kendo-upload-font-size;
$kendo-upload-max-height: $tb-kendo-upload-max-height;
$kendo-upload-dropzone-padding-x: $tb-kendo-upload-dropzone-padding-x;
$kendo-upload-dropzone-padding-y: $tb-kendo-upload-dropzone-padding-y;
$kendo-upload-item-padding-x: $tb-kendo-upload-item-padding-x;
$kendo-upload-multiple-items-spacing: $tb-kendo-upload-multiple-items-spacing;
$kendo-upload-validation-font-size: $tb-kendo-upload-validation-font-size;
$kendo-upload-icon-spacing: $tb-kendo-upload-icon-spacing;
$kendo-window-border-width: $tb-kendo-window-border-width;
$kendo-window-border-radius: $tb-kendo-window-border-radius;
$kendo-window-font-size: $tb-kendo-window-font-size;
$kendo-window-titlebar-padding-x: $tb-kendo-window-titlebar-padding-x;
$kendo-window-titlebar-padding-y: $tb-kendo-window-titlebar-padding-y;
$kendo-window-title-font-size: $tb-kendo-window-title-font-size;
$kendo-window-actions-gap: $tb-kendo-window-actions-gap;
$kendo-window-inner-padding-x: $tb-kendo-window-inner-padding-x;
$kendo-window-inner-padding-y: $tb-kendo-window-inner-padding-y;
$kendo-window-buttongroup-padding-x: $tb-kendo-window-buttongroup-padding-x;
$kendo-window-buttongroup-padding-y: $tb-kendo-window-buttongroup-padding-y;
$kendo-window-buttongroup-border-width: $tb-kendo-window-buttongroup-border-width;
$kendo-bottom-nav-padding-y: $tb-kendo-bottom-nav-padding-y;
$kendo-bottom-nav-gap: $tb-kendo-bottom-nav-gap;
$kendo-breadcrumb-icon-link-padding-x: $tb-kendo-breadcrumb-icon-link-padding-x;
$kendo-card-header-padding-x: $tb-kendo-card-header-padding-x;
$kendo-card-header-padding-y: $tb-kendo-card-header-padding-y;
$kendo-card-header-border-width: $tb-kendo-card-header-border-width;
$kendo-card-body-padding-x: $tb-kendo-card-body-padding-x;
$kendo-card-body-padding-y: $tb-kendo-card-body-padding-y;
$kendo-card-footer-padding-x: $tb-kendo-card-footer-padding-x;
$kendo-card-footer-padding-y: $tb-kendo-card-footer-padding-y;
$kendo-card-footer-border-width: $tb-kendo-card-footer-border-width;
$kendo-card-avatar-spacing: $tb-kendo-card-avatar-spacing;
$kendo-color-gradient-padding-y: $tb-kendo-color-gradient-padding-y;
$kendo-color-gradient-padding-x: $tb-kendo-color-gradient-padding-x;
$kendo-color-gradient-gap: $tb-kendo-color-gradient-gap;
$kendo-color-editor-header-padding-y: $tb-kendo-color-editor-header-padding-y;
$kendo-color-editor-header-padding-x: $tb-kendo-color-editor-header-padding-x;
$kendo-color-editor-views-padding-y: $tb-kendo-color-editor-views-padding-y;
$kendo-color-editor-views-padding-x: $tb-kendo-color-editor-views-padding-x;
$kendo-color-editor-views-gap: $tb-kendo-color-editor-views-gap;
$kendo-color-palette-tile-height: $tb-kendo-color-palette-tile-height;
$kendo-dock-manager-pane-content-padding-x: $tb-kendo-dock-manager-pane-content-padding-x;
$kendo-dock-manager-tabbed-pane-padding-x: $tb-kendo-dock-manager-tabbed-pane-padding-x;
$kendo-fab-padding-y: $tb-kendo-fab-padding-y;
$kendo-fab-icon-padding-y: $tb-kendo-fab-icon-padding-y;
$kendo-fab-item-text-padding-y: $tb-kendo-fab-item-text-padding-y;
$kendo-fab-item-icon-padding-y: $tb-kendo-fab-item-icon-padding-y;
$kendo-grid-header-padding-x: $tb-kendo-grid-header-padding-x;
$kendo-grid-header-padding-y: $tb-kendo-grid-header-padding-y;
$kendo-grid-grouping-header-padding-x: $tb-kendo-grid-grouping-header-padding-x;
$kendo-grid-grouping-header-padding-y: $tb-kendo-grid-grouping-header-padding-y;
$kendo-grid-cell-padding-x: $tb-kendo-grid-cell-padding-x;
$kendo-grid-cell-padding-y: $tb-kendo-grid-cell-padding-y;
$kendo-grid-filter-cell-padding-x: $tb-kendo-grid-filter-cell-padding-x;
$kendo-grid-filter-cell-padding-y: $tb-kendo-grid-filter-cell-padding-y;
$kendo-grid-edit-cell-padding-x: $tb-kendo-grid-edit-cell-padding-x;
$kendo-menu-button-arrow-padding-x: $tb-kendo-menu-button-arrow-padding-x;
$kendo-popover-border-width: $tb-kendo-popover-border-width;
$kendo-popover-border-radius: $tb-kendo-popover-border-radius;
$kendo-popover-font-size: $tb-kendo-popover-font-size;
$kendo-popover-header-padding-x: $tb-kendo-popover-header-padding-x;
$kendo-popover-header-padding-y: $tb-kendo-popover-header-padding-y;
$kendo-popover-header-border-width: $tb-kendo-popover-header-border-width;
$kendo-popover-body-padding-x: $tb-kendo-popover-body-padding-x;
$kendo-popover-body-padding-y: $tb-kendo-popover-body-padding-y;
$kendo-popover-actions-border-width: $tb-kendo-popover-actions-border-width;
$kendo-popover-callout-border-width: $tb-kendo-popover-callout-border-width;
$kendo-split-button-arrow-padding-x: $tb-kendo-split-button-arrow-padding-x;
$kendo-split-button-arrow-padding-y: $tb-kendo-split-button-arrow-padding-y;
$kendo-tile-layout-padding-y: $tb-kendo-tile-layout-padding-y;
$kendo-tile-layout-card-border-width: $tb-kendo-tile-layout-card-border-width;
$kendo-tile-layout-hint-border-radius: $tb-kendo-tile-layout-hint-border-radius;
$kendo-body-text: $tb-kendo-body-text;
$kendo-subtle-text: $tb-kendo-subtle-text;
$kendo-disabled-text: $tb-kendo-disabled-text;
$kendo-base-bg: $tb-kendo-base-bg;
$kendo-base-text: $tb-kendo-base-text;
$kendo-base-border: $tb-kendo-base-border;
$kendo-hover-bg: $tb-kendo-hover-bg;
$kendo-selected-bg: $tb-kendo-selected-bg;
$kendo-selected-text: $tb-kendo-selected-text;
$kendo-selected-border: $tb-kendo-selected-border;
$kendo-appbar-light-bg: $tb-kendo-appbar-light-bg;
$kendo-appbar-light-text: $tb-kendo-appbar-light-text;
$kendo-appbar-dark-bg: $tb-kendo-appbar-dark-bg;
$kendo-appbar-dark-text: $tb-kendo-appbar-dark-text;
$kendo-bottom-nav-flat-text: $tb-kendo-bottom-nav-flat-text;
$kendo-bottom-nav-flat-bg: $tb-kendo-bottom-nav-flat-bg;
$kendo-bottom-nav-flat-border: $tb-kendo-bottom-nav-flat-border;
$kendo-breadcrumb-bg: $tb-kendo-breadcrumb-bg;
$kendo-breadcrumb-text: $tb-kendo-breadcrumb-text;
$kendo-breadcrumb-border: $tb-kendo-breadcrumb-border;
$kendo-breadcrumb-link-text: $tb-kendo-breadcrumb-link-text;
$kendo-breadcrumb-link-hover-text: $tb-kendo-breadcrumb-link-hover-text;
$kendo-breadcrumb-root-link-text: $tb-kendo-breadcrumb-root-link-text;
$kendo-breadcrumb-root-link-hover-text: $tb-kendo-breadcrumb-root-link-hover-text;
$kendo-breadcrumb-current-item-text: $tb-kendo-breadcrumb-current-item-text;
$kendo-button-text: $tb-kendo-button-text;
$kendo-button-hover-bg: $tb-kendo-button-hover-bg;
$kendo-button-hover-border: $tb-kendo-button-hover-border;
$kendo-button-active-bg: $tb-kendo-button-active-bg;
$kendo-button-active-border: $tb-kendo-button-active-border;
$kendo-button-selected-bg: $tb-kendo-button-selected-bg;
$kendo-button-selected-text: $tb-kendo-button-selected-text;
$kendo-button-selected-border: $tb-kendo-button-selected-border;
$kendo-button-focus-border: $tb-kendo-button-focus-border;
$kendo-card-bg: $tb-kendo-card-bg;
$kendo-card-text: $tb-kendo-card-text;
$kendo-card-border: $tb-kendo-card-border;
$kendo-card-header-bg: $tb-kendo-card-header-bg;
$kendo-card-header-text: $tb-kendo-card-header-text;
$kendo-card-subtitle-text: $tb-kendo-card-subtitle-text;
$kendo-series-a: $tb-kendo-series-a;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
$kendo-chart-major-lines: $tb-kendo-chart-major-lines;
$kendo-chart-minor-lines: $tb-kendo-chart-minor-lines;
$kendo-checkbox-bg: $tb-kendo-checkbox-bg;
$kendo-checkbox-border: $tb-kendo-checkbox-border;
$kendo-color-primary: $tb-kendo-color-primary;
$kendo-checkbox-checked-text: $tb-kendo-checkbox-checked-text;
$kendo-checkbox-focus-border: $tb-kendo-checkbox-focus-border;
$kendo-checkbox-invalid-text: $tb-kendo-checkbox-invalid-text;
$kendo-checkbox-invalid-border: $tb-kendo-checkbox-invalid-border;
$kendo-chip-base-bg: $tb-kendo-chip-base-bg;
$kendo-chip-solid-border: $tb-kendo-chip-solid-border;
$kendo-chip-outline-bg: $tb-kendo-chip-outline-bg;
$kendo-chip-outline-hover-text: $tb-kendo-chip-outline-hover-text;
$kendo-color-gradient-text: $tb-kendo-color-gradient-text;
$kendo-color-gradient-bg: $tb-kendo-color-gradient-bg;
$kendo-color-gradient-border: $tb-kendo-color-gradient-border;
$kendo-color-gradient-draghandle-bg: $tb-kendo-color-gradient-draghandle-bg;
$kendo-color-gradient-draghandle-border: $tb-kendo-color-gradient-draghandle-border;
$kendo-color-gradient-draghandle-focus-shadow: $tb-kendo-color-gradient-draghandle-focus-shadow;
$kendo-color-gradient-draghandle-hover-shadow: $tb-kendo-color-gradient-draghandle-hover-shadow;
$kendo-color-gradient-input-label-text: $tb-kendo-color-gradient-input-label-text;
$kendo-color-primary-contrast: $tb-kendo-color-primary-contrast;
$kendo-color-secondary: $tb-kendo-color-secondary;
$kendo-color-tertiary: $tb-kendo-color-tertiary;
$kendo-color-success: $tb-kendo-color-success;
$kendo-color-warning: $tb-kendo-color-warning;
$kendo-color-error: $tb-kendo-color-error;
$kendo-color-dark: $tb-kendo-color-dark;
$kendo-color-light: $tb-kendo-color-light;
$kendo-color-inverse: $tb-kendo-color-inverse;
$kendo-color-editor-text: $tb-kendo-color-editor-text;
$kendo-color-editor-bg: $tb-kendo-color-editor-bg;
$kendo-color-editor-border: $tb-kendo-color-editor-border;
$kendo-color-editor-color-gradient-focus-outline-color: $tb-kendo-color-editor-color-gradient-focus-outline-color;
$kendo-component-bg: $tb-kendo-component-bg;
$kendo-component-text: $tb-kendo-component-text;
$kendo-component-border: $tb-kendo-component-border;
$kendo-dock-indicator-hover-text: $tb-kendo-dock-indicator-hover-text;
$kendo-dock-manager-dock-preview-bg: $tb-kendo-dock-manager-dock-preview-bg;
$kendo-dropzone-bg: $tb-kendo-dropzone-bg;
$kendo-dropzone-icon-text: $tb-kendo-dropzone-icon-text;
$kendo-dropzone-note-text: $tb-kendo-dropzone-note-text;
$kendo-editor-placeholder-text: $tb-kendo-editor-placeholder-text;
$kendo-editor-highlighted-bg: $tb-kendo-editor-highlighted-bg;
$kendo-editor-resize-handle-border: $tb-kendo-editor-resize-handle-border;
$kendo-editor-resize-handle-bg: $tb-kendo-editor-resize-handle-bg;
$kendo-editor-selectednode-outline-color: $tb-kendo-editor-selectednode-outline-color;
$kendo-expander-header-bg: $tb-kendo-expander-header-bg;
$kendo-expander-header-hover-bg: $tb-kendo-expander-header-hover-bg;
$kendo-expander-header-sub-title-text: $tb-kendo-expander-header-sub-title-text;
$kendo-filter-preview-operator-text: $tb-kendo-filter-preview-operator-text;
$kendo-grid-bg: $tb-kendo-grid-bg;
$kendo-grid-text: $tb-kendo-grid-text;
$kendo-grid-border: $tb-kendo-grid-border;
$kendo-grid-header-bg: $tb-kendo-grid-header-bg;
$kendo-grid-header-text: $tb-kendo-grid-header-text;
$kendo-grid-header-border: $tb-kendo-grid-header-border;
$kendo-grid-footer-bg: $tb-kendo-grid-footer-bg;
$kendo-grid-footer-text: $tb-kendo-grid-footer-text;
$kendo-grid-footer-border: $tb-kendo-grid-footer-border;
$kendo-grid-alt-bg: $tb-kendo-grid-alt-bg;
$kendo-grid-hover-bg: $tb-kendo-grid-hover-bg;
$kendo-grid-selected-bg: $tb-kendo-grid-selected-bg;
$kendo-grid-selected-text: $tb-kendo-grid-selected-text;
$kendo-grid-row-resizer-hover-bg: $tb-kendo-grid-row-resizer-hover-bg;
$kendo-input-bg: $tb-kendo-input-bg;
$kendo-input-text: $tb-kendo-input-text;
$kendo-input-border: $tb-kendo-input-border;
$kendo-input-focus-bg: $tb-kendo-input-focus-bg;
$kendo-input-focus-text: $tb-kendo-input-focus-text;
$kendo-input-focus-border: $tb-kendo-input-focus-border;
$kendo-input-outline-border: $tb-kendo-input-outline-border;
$kendo-input-placeholder-text: $tb-kendo-input-placeholder-text;
$kendo-input-prefix-text: $tb-kendo-input-prefix-text;
$kendo-input-suffix-text: $tb-kendo-input-suffix-text;
$kendo-input-invalid-border: $tb-kendo-input-invalid-border;
$kendo-input-valid-border: $tb-kendo-input-valid-border;
$kendo-list-no-data-text: $tb-kendo-list-no-data-text;
$kendo-list-option-label-text: $tb-kendo-list-option-label-text;
$kendo-listview-item-selected-bg: $tb-kendo-listview-item-selected-bg;
$kendo-loader-secondary-bg: $tb-kendo-loader-secondary-bg;
$kendo-loader-container-panel-bg: $tb-kendo-loader-container-panel-bg;
$kendo-menu-popup-bg: $tb-kendo-menu-popup-bg;
$kendo-menu-popup-text: $tb-kendo-menu-popup-text;
$kendo-menu-popup-border: $tb-kendo-menu-popup-border;
$kendo-pager-bg: $tb-kendo-pager-bg;
$kendo-pager-text: $tb-kendo-pager-text;
$kendo-pager-border: $tb-kendo-pager-border;
$kendo-picker-outline-border: $tb-kendo-picker-outline-border;
$kendo-picker-outline-hover-text: $tb-kendo-picker-outline-hover-text;
$kendo-picker-flat-hover-bg: $tb-kendo-picker-flat-hover-bg;
$kendo-progressbar-bg: $tb-kendo-progressbar-bg;
$kendo-progressbar-text: $tb-kendo-progressbar-text;
$kendo-rating-icon-text: $tb-kendo-rating-icon-text;
$kendo-scrollview-navigation-color: $tb-kendo-scrollview-navigation-color;
$kendo-scrollview-navigation-bg: $tb-kendo-scrollview-navigation-bg;
$kendo-scrollview-light-bg: $tb-kendo-scrollview-light-bg;
$kendo-scrollview-dark-bg: $tb-kendo-scrollview-dark-bg;
$kendo-skeleton-item-bg: $tb-kendo-skeleton-item-bg;
$kendo-skeleton-wave-bg: $tb-kendo-skeleton-wave-bg;
$kendo-switch-off-track-border: $tb-kendo-switch-off-track-border;
$kendo-switch-off-track-focus-border: $tb-kendo-switch-off-track-focus-border;
$kendo-switch-off-track-disabled-bg: $tb-kendo-switch-off-track-disabled-bg;
$kendo-switch-off-thumb-bg: $tb-kendo-switch-off-thumb-bg;
$kendo-switch-on-track-disabled-bg: $tb-kendo-switch-on-track-disabled-bg;
$kendo-switch-on-thumb-bg: $tb-kendo-switch-on-thumb-bg;
$kendo-table-header-bg: $tb-kendo-table-header-bg;
$kendo-table-header-text: $tb-kendo-table-header-text;
$kendo-table-header-border: $tb-kendo-table-header-border;
$kendo-table-alt-row-bg: $tb-kendo-table-alt-row-bg;
$kendo-table-hover-bg: $tb-kendo-table-hover-bg;
$kendo-table-selected-bg: $tb-kendo-table-selected-bg;
$kendo-tabstrip-item-text: $tb-kendo-tabstrip-item-text;
$kendo-tabstrip-item-hover-text: $tb-kendo-tabstrip-item-hover-text;
$kendo-tabstrip-item-hover-border: $tb-kendo-tabstrip-item-hover-border;
$kendo-tile-layout-bg: $tb-kendo-tile-layout-bg;
$kendo-tile-layout-hint-bg: $tb-kendo-tile-layout-hint-bg;
$kendo-toolbar-bg: $tb-kendo-toolbar-bg;
$kendo-toolbar-text: $tb-kendo-toolbar-text;
$kendo-toolbar-border: $tb-kendo-toolbar-border;
$kendo-tooltip-bg: $tb-kendo-tooltip-bg;
$kendo-tooltip-text: $tb-kendo-tooltip-text;
$kendo-treeview-loadmore-bg: $tb-kendo-treeview-loadmore-bg;
$kendo-treeview-loadmore-text: $tb-kendo-treeview-loadmore-text;
$kendo-treeview-loadmore-hover-bg: $tb-kendo-treeview-loadmore-hover-bg;
$kendo-treeview-loadmore-hover-text: $tb-kendo-treeview-loadmore-hover-text;
$kendo-treeview-loadmore-focus-bg: $tb-kendo-treeview-loadmore-focus-bg;
$kendo-treeview-loadmore-focus-text: $tb-kendo-treeview-loadmore-focus-text;
$kendo-upload-dropzone-text: $tb-kendo-upload-dropzone-text;
$kendo-upload-dropzone-bg: $tb-kendo-upload-dropzone-bg;
$kendo-upload-dropzone-hover-bg: $tb-kendo-upload-dropzone-hover-bg;
$kendo-upload-status-text: $tb-kendo-upload-status-text;
$kendo-upload-icon-color: $tb-kendo-upload-icon-color;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-hover-text: $tb-kendo-hover-text;
$kendo-hover-border: $tb-kendo-hover-border;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-border: $tb-kendo-button-border;
$kendo-card-header-border: $tb-kendo-card-header-border;
$kendo-card-footer-bg: $tb-kendo-card-footer-bg;
$kendo-card-footer-text: $tb-kendo-card-footer-text;
$kendo-card-footer-border: $tb-kendo-card-footer-border;
$kendo-series-1: $tb-kendo-series-1;
$kendo-checkbox-checked-bg: $tb-kendo-checkbox-checked-bg;
$kendo-checkbox-checked-border: $tb-kendo-checkbox-checked-border;
$kendo-checkbox-indeterminate-bg: $tb-kendo-checkbox-indeterminate-bg;
$kendo-checkbox-indeterminate-text: $tb-kendo-checkbox-indeterminate-text;
$kendo-checkbox-indeterminate-border: $tb-kendo-checkbox-indeterminate-border;
$kendo-checkbox-focus-checked-border: $tb-kendo-checkbox-focus-checked-border;
$kendo-checkbox-ripple-bg: $tb-kendo-checkbox-ripple-bg;
$kendo-chip-solid-bg: $tb-kendo-chip-solid-bg;
$kendo-chip-solid-text: $tb-kendo-chip-solid-text;
$kendo-chip-solid-hover-bg: $tb-kendo-chip-solid-hover-bg;
$kendo-chip-solid-selected-bg: $tb-kendo-chip-solid-selected-bg;
$kendo-chip-outline-text: $tb-kendo-chip-outline-text;
$kendo-chip-outline-border: $tb-kendo-chip-outline-border;
$kendo-chip-outline-hover-bg: $tb-kendo-chip-outline-hover-bg;
$kendo-chip-outline-selected-bg: $tb-kendo-chip-outline-selected-bg;
$kendo-chip-outline-selected-text: $tb-kendo-chip-outline-selected-text;
$kendo-color-gradient-focus-border: $tb-kendo-color-gradient-focus-border;
$kendo-color-info: $tb-kendo-color-info;
$kendo-color-editor-focus-border: $tb-kendo-color-editor-focus-border;
$kendo-dock-manager-border: $tb-kendo-dock-manager-border;
$kendo-dock-manager-pane-header-bg: $tb-kendo-dock-manager-pane-header-bg;
$kendo-dock-manager-unpinned-container-bg: $tb-kendo-dock-manager-unpinned-container-bg;
$kendo-dock-indicator-bg: $tb-kendo-dock-indicator-bg;
$kendo-dock-indicator-text: $tb-kendo-dock-indicator-text;
$kendo-dock-indicator-outline: $tb-kendo-dock-indicator-outline;
$kendo-dock-indicator-hover-bg: $tb-kendo-dock-indicator-hover-bg;
$kendo-dock-manager-dock-preview-border: $tb-kendo-dock-manager-dock-preview-border;
$kendo-dropzone-text: $tb-kendo-dropzone-text;
$kendo-dropzone-border: $tb-kendo-dropzone-border;
$kendo-dropzone-icon-hover-text: $tb-kendo-dropzone-icon-hover-text;
$kendo-editor-selected-text: $tb-kendo-editor-selected-text;
$kendo-editor-selected-bg: $tb-kendo-editor-selected-bg;
$kendo-expander-text: $tb-kendo-expander-text;
$kendo-expander-bg: $tb-kendo-expander-bg;
$kendo-expander-border: $tb-kendo-expander-border;
$kendo-expander-header-text: $tb-kendo-expander-header-text;
$kendo-expander-title-text: $tb-kendo-expander-title-text;
$kendo-filter-preview-field-text: $tb-kendo-filter-preview-field-text;
$kendo-fab-item-text: $tb-kendo-fab-item-text;
$kendo-fab-item-bg: $tb-kendo-fab-item-bg;
$kendo-fab-item-border: $tb-kendo-fab-item-border;
$kendo-fab-item-icon-text: $tb-kendo-fab-item-icon-text;
$kendo-fab-item-icon-bg: $tb-kendo-fab-item-icon-bg;
$kendo-fab-item-icon-border: $tb-kendo-fab-item-icon-border;
$kendo-fab-item-outline-color: $tb-kendo-fab-item-outline-color;
$kendo-form-legend-border-color: $tb-kendo-form-legend-border-color;
$kendo-form-separator-border-color: $tb-kendo-form-separator-border-color;
$kendo-grid-selection-aggregates-bg: $tb-kendo-grid-selection-aggregates-bg;
$kendo-grid-selection-aggregates-text: $tb-kendo-grid-selection-aggregates-text;
$kendo-grid-selection-aggregates-border: $tb-kendo-grid-selection-aggregates-border;
$kendo-grid-row-resizer-active-bg: $tb-kendo-grid-row-resizer-active-bg;
$kendo-input-outline-text: $tb-kendo-input-outline-text;
$kendo-input-outline-focus-border: $tb-kendo-input-outline-focus-border;
$kendo-input-flat-text: $tb-kendo-input-flat-text;
$kendo-input-flat-border: $tb-kendo-input-flat-border;
$kendo-input-flat-focus-border: $tb-kendo-input-flat-focus-border;
$kendo-input-separator-text: $tb-kendo-input-separator-text;
$kendo-list-bg: $tb-kendo-list-bg;
$kendo-list-text: $tb-kendo-list-text;
$kendo-list-border: $tb-kendo-list-border;
$kendo-list-item-hover-bg: $tb-kendo-list-item-hover-bg;
$kendo-list-item-hover-text: $tb-kendo-list-item-hover-text;
$kendo-list-item-selected-bg: $tb-kendo-list-item-selected-bg;
$kendo-list-item-selected-text: $tb-kendo-list-item-selected-text;
$kendo-listbox-text: $tb-kendo-listbox-text;
$kendo-listbox-bg: $tb-kendo-listbox-bg;
$kendo-listbox-border: $tb-kendo-listbox-border;
$kendo-listview-text: $tb-kendo-listview-text;
$kendo-listview-bg: $tb-kendo-listview-bg;
$kendo-listview-border: $tb-kendo-listview-border;
$kendo-loader-container-panel-border-color: $tb-kendo-loader-container-panel-border-color;
$kendo-loading-bg: $tb-kendo-loading-bg;
$kendo-menu-popup-item-hover-bg: $tb-kendo-menu-popup-item-hover-bg;
$kendo-menu-popup-item-hover-text: $tb-kendo-menu-popup-item-hover-text;
$kendo-menu-popup-item-expanded-bg: $tb-kendo-menu-popup-item-expanded-bg;
$kendo-menu-popup-item-expanded-text: $tb-kendo-menu-popup-item-expanded-text;
$kendo-notification-bg: $tb-kendo-notification-bg;
$kendo-notification-text: $tb-kendo-notification-text;
$kendo-notification-border: $tb-kendo-notification-border;
$kendo-picker-bg: $tb-kendo-picker-bg;
$kendo-picker-text: $tb-kendo-picker-text;
$kendo-picker-border: $tb-kendo-picker-border;
$kendo-picker-hover-bg: $tb-kendo-picker-hover-bg;
$kendo-picker-hover-border: $tb-kendo-picker-hover-border;
$kendo-picker-focus-bg: $tb-kendo-picker-focus-bg;
$kendo-picker-focus-border: $tb-kendo-picker-focus-border;
$kendo-picker-outline-text: $tb-kendo-picker-outline-text;
$kendo-picker-outline-hover-bg: $tb-kendo-picker-outline-hover-bg;
$kendo-picker-outline-hover-border: $tb-kendo-picker-outline-hover-border;
$kendo-picker-flat-text: $tb-kendo-picker-flat-text;
$kendo-picker-flat-border: $tb-kendo-picker-flat-border;
$kendo-popover-text: $tb-kendo-popover-text;
$kendo-popover-bg: $tb-kendo-popover-bg;
$kendo-popover-border: $tb-kendo-popover-border;
$kendo-popup-bg: $tb-kendo-popup-bg;
$kendo-popup-text: $tb-kendo-popup-text;
$kendo-popup-border: $tb-kendo-popup-border;
$kendo-progressbar-value-bg: $tb-kendo-progressbar-value-bg;
$kendo-progressbar-value-text: $tb-kendo-progressbar-value-text;
$kendo-progressbar-indeterminate-bg: $tb-kendo-progressbar-indeterminate-bg;
$kendo-progressbar-indeterminate-text: $tb-kendo-progressbar-indeterminate-text;
$kendo-circular-progressbar-arc-stroke: $tb-kendo-circular-progressbar-arc-stroke;
$kendo-radio-bg: $tb-kendo-radio-bg;
$kendo-radio-border: $tb-kendo-radio-border;
$kendo-radio-checked-bg: $tb-kendo-radio-checked-bg;
$kendo-radio-checked-text: $tb-kendo-radio-checked-text;
$kendo-radio-checked-border: $tb-kendo-radio-checked-border;
$kendo-radio-focus-border: $tb-kendo-radio-focus-border;
$kendo-radio-focus-checked-border: $tb-kendo-radio-focus-checked-border;
$kendo-radio-invalid-text: $tb-kendo-radio-invalid-text;
$kendo-radio-invalid-border: $tb-kendo-radio-invalid-border;
$kendo-radio-ripple-bg: $tb-kendo-radio-ripple-bg;
$kendo-rating-icon-selected-text: $tb-kendo-rating-icon-selected-text;
$kendo-rating-icon-hover-text: $tb-kendo-rating-icon-hover-text;
$kendo-rating-icon-focus-text: $tb-kendo-rating-icon-focus-text;
$kendo-scrollview-text: $tb-kendo-scrollview-text;
$kendo-scrollview-bg: $tb-kendo-scrollview-bg;
$kendo-scrollview-border: $tb-kendo-scrollview-border;
$kendo-scrollview-pagebutton-bg: $tb-kendo-scrollview-pagebutton-bg;
$kendo-scrollview-pagebutton-border: $tb-kendo-scrollview-pagebutton-border;
$kendo-scrollview-pagebutton-primary-bg: $tb-kendo-scrollview-pagebutton-primary-bg;
$kendo-scrollview-pagebutton-primary-border: $tb-kendo-scrollview-pagebutton-primary-border;
$kendo-switch-off-track-bg: $tb-kendo-switch-off-track-bg;
$kendo-switch-on-track-bg: $tb-kendo-switch-on-track-bg;
$kendo-switch-on-track-border: $tb-kendo-switch-on-track-border;
$kendo-table-bg: $tb-kendo-table-bg;
$kendo-table-text: $tb-kendo-table-text;
$kendo-table-border: $tb-kendo-table-border;
$kendo-table-footer-bg: $tb-kendo-table-footer-bg;
$kendo-table-footer-text: $tb-kendo-table-footer-text;
$kendo-table-footer-border: $tb-kendo-table-footer-border;
$kendo-table-group-row-bg: $tb-kendo-table-group-row-bg;
$kendo-table-group-row-text: $tb-kendo-table-group-row-text;
$kendo-table-group-row-border: $tb-kendo-table-group-row-border;
$kendo-table-selected-text: $tb-kendo-table-selected-text;
$kendo-tabstrip-text: $tb-kendo-tabstrip-text;
$kendo-tabstrip-border: $tb-kendo-tabstrip-border;
$kendo-tabstrip-item-selected-bg: $tb-kendo-tabstrip-item-selected-bg;
$kendo-tabstrip-item-selected-text: $tb-kendo-tabstrip-item-selected-text;
$kendo-tabstrip-item-selected-border: $tb-kendo-tabstrip-item-selected-border;
$kendo-tabstrip-content-bg: $tb-kendo-tabstrip-content-bg;
$kendo-tabstrip-content-text: $tb-kendo-tabstrip-content-text;
$kendo-tabstrip-content-border: $tb-kendo-tabstrip-content-border;
$kendo-tabstrip-content-focus-border: $tb-kendo-tabstrip-content-focus-border;
$kendo-tile-layout-hint-border: $tb-kendo-tile-layout-hint-border;
$kendo-tooltip-border: $tb-kendo-tooltip-border;
$kendo-treeview-text: $tb-kendo-treeview-text;
$kendo-treeview-item-hover-bg: $tb-kendo-treeview-item-hover-bg;
$kendo-treeview-item-hover-text: $tb-kendo-treeview-item-hover-text;
$kendo-treeview-item-hover-border: $tb-kendo-treeview-item-hover-border;
$kendo-treeview-item-selected-bg: $tb-kendo-treeview-item-selected-bg;
$kendo-treeview-item-selected-text: $tb-kendo-treeview-item-selected-text;
$kendo-treeview-item-selected-border: $tb-kendo-treeview-item-selected-border;
$kendo-upload-text: $tb-kendo-upload-text;
$kendo-upload-bg: $tb-kendo-upload-bg;
$kendo-upload-border: $tb-kendo-upload-border;
$kendo-upload-dropzone-border: $tb-kendo-upload-dropzone-border;
$kendo-upload-progress-bg: $tb-kendo-upload-progress-bg;
$kendo-upload-success-text: $tb-kendo-upload-success-text;
$kendo-upload-success-bg: $tb-kendo-upload-success-bg;
$kendo-upload-error-text: $tb-kendo-upload-error-text;
$kendo-upload-error-bg: $tb-kendo-upload-error-bg;
$kendo-window-bg: $tb-kendo-window-bg;
$kendo-window-text: $tb-kendo-window-text;
$kendo-window-border: $tb-kendo-window-border;
$kendo-window-titlebar-border: $tb-kendo-window-titlebar-border;
$kendo-appbar-box-shadow: $tb-kendo-appbar-box-shadow;
$kendo-appbar-bottom-box-shadow: $tb-kendo-appbar-bottom-box-shadow;
$kendo-bottom-nav-shadow: $tb-kendo-bottom-nav-shadow;
$kendo-card-focus-shadow: $tb-kendo-card-focus-shadow;
$kendo-fab-shadow: $tb-kendo-fab-shadow;
$kendo-fab-disabled-shadow: $tb-kendo-fab-disabled-shadow;
$kendo-popup-shadow: $tb-kendo-popup-shadow;
$kendo-tooltip-shadow: $tb-kendo-tooltip-shadow;
    $kendo-font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    $kendo-font-size: 1rem;
    $kendo-font-weight-normal: 400;
    $kendo-line-height: 1.5;
    
$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
      font-family: 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      font-size: 1rem,
      font-weight: 400,
      line-height: 1.5,
        ),
  figma-agenda: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: bold,
      line-height: 28px,
        ),
  figma-agenda-uppercase: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 28px,
        ),
  figma-badge-text: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 14px,
        ),
  figma-base-text-medium: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: 500,
      line-height: 24px,
        ),
  figma-base-text-small: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 20px,
        ),
  figma-base-text-underline: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 24px,
        ),
  figma-base-text-1: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 24px,
        ),
  figma-base-text-bold: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: bold,
      line-height: 24px,
        ),
  figma-base-text-uppercase: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 24px,
        ),
  figma-bottom-navigation-item-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 18px,
        ),
  figma-card-header-text: (
      font-family: 'Roboto',
      font-size: 20px,
      font-weight: 500,
      line-height: 24px,
        ),
  figma-check-box-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 18px,
        ),
  figma-conversational-ui-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 20px,
        ),
  figma-dialog-header-title: (
      font-family: 'Roboto',
      font-size: 20px,
      font-weight: normal,
      line-height: 30px,
        ),
  figma-display-1: (
      font-family: 'Roboto',
      font-size: 84px,
      font-weight: 300,
      line-height: 100.80000305175781px,
        ),
  figma-display-2: (
      font-family: 'Roboto',
      font-size: 70px,
      font-weight: 300,
      line-height: 84px,
        ),
  figma-display-3: (
      font-family: 'Roboto',
      font-size: 56px,
      font-weight: 300,
      line-height: 67.19999694824219px,
        ),
  figma-display-4: (
      font-family: 'Roboto',
      font-size: 42px,
      font-weight: 300,
      line-height: 50.400001525878906px,
        ),
  figma-drawer-text: (
      font-family: 'Roboto',
      font-size: 19.200000762939453px,
      font-weight: normal,
      line-height: 24px,
        ),
  figma-drawer-tool-bar-title: (
      font-family: 'Roboto',
      font-size: 17px,
      font-weight: bold,
      line-height: 38px,
        ),
  figma-header-1: (
      font-family: 'Roboto',
      font-size: 28px,
      font-weight: normal,
      line-height: 37.2400016784668px,
        ),
  figma-header-2: (
      font-family: 'Roboto',
      font-size: 19px,
      font-weight: normal,
      line-height: 25.270000457763672px,
        ),
  figma-header-3: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: bold,
      line-height: 18.6200008392334px,
        ),
  figma-header-4: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: bold,
      line-height: 15.960000038146973px,
        ),
  figma-header-5: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 15.960000038146973px,
        ),
  figma-header-6: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 15.960000038146973px,
        ),
  figma-hint-text: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 18px,
        ),
  figma-layout-helper: (
      font-family: 'Roboto',
      font-size: 10px,
      font-weight: normal,
      line-height: 15.960000038146973px,
        ),
  figma-list-item-text: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 24px,
        ),
  figma-scheduler-event: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 17px,
        ),
  figma-italic: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 20px,
      text-style: 'italic',
      ),
  figma-bold: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: bold,
      line-height: 20px,
        ),
  figma-small: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 20px,
        ),
  figma-tooltip: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 21px,
        ),
  figma-upload-small-text: (
      font-family: 'Roboto',
      font-size: 11px,
      font-weight: normal,
      line-height: 100%,
        ),
  figma-typography-badge-text: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 100%,
        ),
  figma-typography-base-text-lg-large: (
      font-family: 'Roboto',
      font-size: 20px,
      font-weight: normal,
      line-height: 150%,
        ),
  figma-typography-base-text-md-default-base: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 150%,
        ),
  figma-typography-base-text-md-default-base-bold: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: bold,
      line-height: 150%,
        ),
  figma-typography-base-text-md-default-base-medium: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: 500,
      line-height: 150%,
        ),
  figma-typography-base-text-md-default-base-semi-bold: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: 600,
      line-height: 150%,
        ),
  figma-typography-base-text-md-default-base-underline: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 150%,
          text-decoration-line: underline,
),
  figma-typography-base-text-sm-small: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 141.99999570846558%,
        ),
  figma-typography-base-text-sm-small-bold: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: bold,
      line-height: 141.99999570846558%,
        ),
  figma-typography-base-text-sm-small-italic: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 141.99999570846558%,
      text-style: 'italic',
      ),
  figma-typography-base-text-sm-small-medium: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: 500,
      line-height: 141.99999570846558%,
        ),
  figma-typography-base-text-xl-extra-large: (
      font-family: 'Roboto',
      font-size: 24px,
      font-weight: normal,
      line-height: 150%,
        ),
  figma-typography-base-text-xs-extra-small: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 150%,
        ),
  figma-typography-bottom-nav-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 18px,
        ),
  figma-typography-chart-label: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 14px,
        ),
  figma-typography-chat-message-author: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: bold,
      line-height: 16px,
        ),
  figma-typography-chat-message-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 20px,
        ),
  figma-typography-display-display-1: (
      font-family: 'Roboto',
      font-size: 80px,
      font-weight: 300,
      line-height: 120.00000476837158%,
        ),
  figma-typography-display-display-2: (
      font-family: 'Roboto',
      font-size: 72px,
      font-weight: 300,
      line-height: 120.00000476837158%,
        ),
  figma-typography-display-display-3: (
      font-family: 'Roboto',
      font-size: 64px,
      font-weight: 300,
      line-height: 120.00000476837158%,
        ),
  figma-typography-display-display-4: (
      font-family: 'Roboto',
      font-size: 56px,
      font-weight: 300,
      line-height: 120.00000476837158%,
        ),
  figma-typography-drawer-text: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: normal,
      line-height: 32px,
        ),
  figma-typography-heading-heading-1: (
      font-family: 'Roboto',
      font-size: 40px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-heading-heading-2: (
      font-family: 'Roboto',
      font-size: 32px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-heading-heading-3: (
      font-family: 'Roboto',
      font-size: 28px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-heading-heading-4: (
      font-family: 'Roboto',
      font-size: 24px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-heading-heading-5: (
      font-family: 'Roboto',
      font-size: 20px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-heading-heading-6: (
      font-family: 'Roboto',
      font-size: 16px,
      font-weight: 500,
      line-height: 120.00000476837158%,
        ),
  figma-typography-layout-helper: (
      font-family: 'Roboto',
      font-size: 12px,
      font-weight: normal,
      line-height: 16px,
        text-transform: uppercase,
    ),
  figma-typography-scheduler-event: (
      font-family: 'Roboto',
      font-size: 14px,
      font-weight: normal,
      line-height: 17px,
        ),
  figma-typography-upload-status-text: (
      font-family: 'Roboto',
      font-size: 11px,
      font-weight: normal,
      line-height: 11px,
        ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
      box-shadow: (none), filter: (none), backdrop-filter: (none),  ),
  kendo-appbar-box-shadow: (
      box-shadow: (0px 6px 13px 0 rgba(0, 0, 0, 0.125)),  ),
  kendo-appbar-bottom-box-shadow: (
      box-shadow: (0px 6px 13px 0 rgba(0, 0, 0, 0.125)),  ),
  kendo-bottom-nav-shadow: (
      box-shadow: (0px 6px 13px 0 rgba(0, 0, 0, 0.125)),  ),
  kendo-card-focus-shadow: (
      box-shadow: (0px 4px 10px 0 rgba(0, 0, 0, 0.1)),  ),
  kendo-fab-shadow: (
      box-shadow: (0px 8px 16px 0px rgba(0, 0, 0, 0.15)),  ),
  kendo-fab-disabled-shadow: (
      box-shadow: (0px 8px 16px 0px rgba(0, 0, 0, 0.15)),  ),
  kendo-popup-shadow: (
      box-shadow: (0px 6px 13px 0 rgba(0, 0, 0, 0.125)),  ),
  kendo-tooltip-shadow: (
      box-shadow: (0px 2px 7px 0 rgba(0, 0, 0, 0.075)),  ),
  figma-shadow: (
      box-shadow: (0px 6px 30px 5px rgba(0, 0, 0, 0.11999999731779099), 0px 16px 24px 2px rgba(0, 0, 0, 0.14000000059604645), 0px 8px 10px -5px rgba(0, 0, 0, 0.20000000298023224)),  ),
  figma-shadow-1: (
      box-shadow: (0px 4px 4px 0 rgba(0, 0, 0, 0.25)),  ),
  figma-breadcrumb-item-focused: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.25)),  ),
  figma-flat-button-group-focused: (
      box-shadow: (inset 0px 0px 0px 2px rgba(33, 37, 41, 0.11999999731779099)),  ),
  figma-solid-button-group-focused: (
      box-shadow: (0px 0px 0px 4px rgba(228, 231, 235, 0.5)),  ),
  figma-focused-1: (
      box-shadow: (0px 0px 0px 4px rgba(167, 151, 230, 0.699999988079071)),  ),
  figma-focused-2: (
      box-shadow: (0px 0px 0px 4px rgba(228, 231, 235, 0.5)),  ),
  figma-focused-3: (
      box-shadow: (0px 0px 0px 4px rgba(33, 37, 41, 0.5)),  ),
  figma-chip-focused: (
      box-shadow: (0px 0px 0px 2px rgba(33, 37, 41, 0.1599999964237213)),  ),
  figma-chip-error-focused: (
      box-shadow: (0px 0px 0px 3px rgba(220, 53, 69, 0.1599999964237213)),  ),
  figma-chip-info-focused: (
      box-shadow: (0px 0px 0px 3px rgba(167, 151, 230, 0.1599999964237213)),  ),
  figma-chip-success-focused: (
      box-shadow: (0px 0px 0px 3px rgba(25, 135, 84, 0.1599999964237213)),  ),
  figma-chip-warning-focused: (
      box-shadow: (0px 0px 0px 3px rgba(255, 193, 7, 0.1599999964237213)),  ),
  figma-handle-hover-focus: (
      box-shadow: (0px 1px 4px 0 rgba(0, 0, 0, 1)),  ),
  figma-handle-normal: (
      box-shadow: (0px 1px 4px 0 rgba(0, 0, 0, 0.5)),  ),
  figma-palette-tile-hover-focus: (
      box-shadow: (0px 1px 3px 1px rgba(0, 0, 0, 0.5)),  ),
  figma-palette-tile-selected: (
      box-shadow: (0px 1px 3px 1px rgba(0, 0, 0, 0.30000001192092896)),  ),
  figma-drawer-item-focused: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.15000000596046448)),  ),
  figma-fab-focused: (
      box-shadow: (0px 2px 4px 0 rgba(0, 0, 0, 0.20000000298023224), 0px 4px 5px 0 rgba(0, 0, 0, 0.23999999463558197), 0px 1px 10px 0 rgba(0, 0, 0, 0.2199999988079071)),  ),
  figma-flat-input-border-focused: (
      box-shadow: (inset 0px -1px 0px 0 rgba(13, 110, 253, 1)),  ),
  figma-flat-input-border-invalid: (
      box-shadow: (inset 0px -1px 0px 0 rgba(220, 53, 69, 1)),  ),
  figma-flat-input-border-normal: (
      box-shadow: (inset 0px -1px 0px 0 rgba(206, 212, 218, 1)),  ),
  figma-floating-action-button: (
      box-shadow: (0px 3px 5px -1px rgba(0, 0, 0, 0.20000000298023224), 0px 1px 18px 0 rgba(0, 0, 0, 0.11999999731779099), 0px 6px 10px 0 rgba(0, 0, 0, 0.14000000059604645)),  ),
  figma-task-focus: (
      box-shadow: (0px 3px 5px 1px rgba(0, 0, 0, 0.5)),  ),
  figma-toolbar-focus: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.07999999821186066)),  ),
  figma-grid-cell-focused: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.07999999821186066)),  ),
  figma-input-button-border-focused: (
      box-shadow: (inset 1px 0px 0px 0 rgba(134, 183, 254, 1)),  ),
  figma-input-button-border-normal: (
      box-shadow: (inset 1px 0px 0px 0 rgba(33, 34, 89, 0.5)),  ),
  figma-input-button-invalid-border: (
      box-shadow: (inset 1px 0px 0px 0 rgba(220, 53, 69, 1)),  ),
  figma-focused-4: (
      box-shadow: (0px 0px 0px 4px rgba(167, 151, 230, 0.5)),  ),
  figma-invalid: (
      box-shadow: (0px 0px 0px 4px rgba(220, 53, 69, 0.25)),  ),
  figma-keyboard-focus-border: (
      box-shadow: (-2px -2px 0px 0 rgba(179, 179, 179, 1), 2px 2px 0px 0 rgba(179, 179, 179, 1)),  ),
  figma-list-item-focused: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.1599999964237213)),  ),
  figma-navigation-button-focused: (
      box-shadow: (inset 0px 0px 0px 3px rgba(228, 231, 235, 0.5)),  ),
  figma-notification-drop-shadow: (
      box-shadow: (0px 4px 5px 0 rgba(0, 0, 0, 0.03999999910593033), 0px 2px 4px 0 rgba(0, 0, 0, 0.029999999329447746)),  ),
  figma-popup-shadow: (
      box-shadow: (0px 4px 5px 0 rgba(0, 0, 0, 0.03999999910593033), 0px 2px 4px 0 rgba(0, 0, 0, 0.029999999329447746)),  ),
  figma-tree-view-item-focused: (
      box-shadow: (inset 0px 0px 0px 2px rgba(222, 226, 230, 1)),  ),
  figma-tree-view-item-selected-focus: (
      box-shadow: (0px 0px 0px 2px rgba(13, 110, 253, 0.5)),  ),
  figma-effects-elevation-kendo-elevation-1: (
      box-shadow: (0px 1px 2px 0 rgba(0, 0, 0, 0.03799999877810478)),  ),
  figma-effects-elevation-kendo-elevation-2: (
      box-shadow: (0px 2px 7px 0 rgba(0, 0, 0, 0.07500000298023224)),  ),
  figma-effects-elevation-kendo-elevation-3: (
      box-shadow: (0px 4px 10px 0 rgba(0, 0, 0, 0.10000000149011612)),  ),
  figma-effects-elevation-kendo-elevation-4: (
      box-shadow: (0px 6px 13px 0 rgba(0, 0, 0, 0.125)),  ),
  figma-effects-elevation-kendo-elevation-5: (
      box-shadow: (0px 8px 16px 0 rgba(0, 0, 0, 0.15000000596046448)),  ),
  figma-effects-elevation-kendo-elevation-6: (
      box-shadow: (0px 11px 24px 0 rgba(0, 0, 0, 0.1589999943971634)),  ),
  figma-effects-elevation-kendo-elevation-7: (
      box-shadow: (0px 14px 36px 0 rgba(0, 0, 0, 0.1679999977350235)),  ),
  figma-effects-elevation-kendo-elevation-8: (
      box-shadow: (0px 16px 48px 0 rgba(0, 0, 0, 0.17599999904632568)),  ),
  figma-effects-elevation-kendo-elevation-9: (
      box-shadow: (0px 18px 60px 0 rgba(0, 0, 0, 0.1850000023841858)),  ),
  figma-effects-focus-action-sheet-kendo-actionsheet-item-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.12999999523162842)),  ),
  figma-effects-focus-breadcrumb-kendo-breadcrumb-link-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.25)),  ),
  figma-effects-focus-button-kendo-dark-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(33, 37, 41, 0.5)),  ),
  figma-effects-focus-button-kendo-error-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(220, 53, 69, 0.5)),  ),
  figma-effects-focus-button-kendo-info-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(13, 202, 240, 0.5)),  ),
  figma-effects-focus-button-kendo-light-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(248, 249, 250, 0.5)),  ),
  figma-effects-focus-button-kendo-outline-base-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(33, 37, 41, 0.5)),  ),
  figma-effects-focus-button-kendo-secondary-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(108, 117, 125, 0.5)),  ),
  figma-effects-focus-button-kendo-solid-base-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(222, 226, 230, 0.5)),  ),
  figma-effects-focus-button-kendo-solid-primary-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(13, 110, 253, 0.5)),  ),
  figma-effects-focus-button-kendo-success-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(25, 135, 84, 0.5)),  ),
  figma-effects-focus-button-kendo-tertiary-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(111, 66, 193, 0.5)),  ),
  figma-effects-focus-button-kendo-warning-button-focus: (
      box-shadow: (0px 0px 0px 4px rgba(255, 193, 7, 0.5)),  ),
  figma-effects-focus-calendar-calendar-cell-focus: (
      box-shadow: (inset 0px 0px 0px 4px rgba(222, 226, 230, 0.5)),  ),
  figma-effects-focus-checkbox-kendo-checkbox-focus: (
      box-shadow: (0px 0px 0px 4px rgba(13, 110, 253, 0.25)),  ),
  figma-effects-focus-chip-kendo-error-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(220, 53, 69, 0.1599999964237213)),  ),
  figma-effects-focus-chip-kendo-info-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(13, 202, 240, 0.1599999964237213)),  ),
  figma-effects-focus-chip-kendo-outline-base-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(33, 37, 41, 0.1599999964237213)),  ),
  figma-effects-focus-chip-kendo-solid-base-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(222, 226, 230, 0.1599999964237213)),  ),
  figma-effects-focus-chip-kendo-success-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(25, 135, 84, 0.1599999964237213)),  ),
  figma-effects-focus-chip-kendo-warning-chip-focus: (
      box-shadow: (0px 0px 0px 2px rgba(255, 193, 7, 0.1599999964237213)),  ),
  figma-effects-focus-color-palette-color-palette-tile-focus: (
      box-shadow: (0px 0px 3px 1px rgba(0, 0, 0, 0.30000001192092896), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.5)),  ),
  figma-effects-focus-color-palette-color-palette-tile-hover: (
      box-shadow: (0px 0px 3px 1px rgba(0, 0, 0, 0.30000001192092896), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.800000011920929)),  ),
  figma-effects-focus-color-palette-color-palette-tile-selected: (
      box-shadow: (0px 1px 3px 1px rgba(0, 0, 0, 0.30000001192092896), inset 0px 0px 0px 1px rgba(255, 255, 255, 1)),  ),
  figma-effects-focus-color-picker-color-picker-focus-handle: (
      box-shadow: (0px 0px 0px 3px rgba(13, 110, 253, 0.5)),  ),
  figma-effects-focus-drawer-kendo-drawer-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.15000000596046448)),  ),
  figma-effects-focus-gantt-task-focus: (
      box-shadow: (0px 3px 5px 1px rgba(0, 0, 0, 0.5)),  ),
  figma-effects-focus-gantt-toolbar-focus: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.07999999821186066)),  ),
  figma-effects-focus-grid-kendo-grid-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 2px rgba(0, 0, 0, 0.07999999821186066)),  ),
  figma-effects-focus-grid-pivotgridcell-focus: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.15000000596046448)),  ),
  figma-effects-focus-input-kendo-input-invalid-shadow: (
      box-shadow: (0px 0px 0px 4px rgba(220, 53, 69, 0.25)),  ),
  figma-effects-focus-input-kendo-switch-trask-focus: (
      box-shadow: (0px 0px 0px 4px rgba(13, 110, 253, 0.25)),  ),
  figma-effects-focus-list-item-kendo-list-item-focus: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.15000000596046448)),  ),
  figma-effects-focus-menu-kendo-menu-item-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 3px rgba(33, 37, 41, 0.15000000596046448)),  ),
  figma-effects-focus-scheduler-kendo-scheduler-event-selected-shadow: (
      box-shadow: (inset 0px 0px 0px 3px rgba(255, 255, 255, 0.5)),  ),
  figma-effects-focus-stepper-stepper-label-focus: (
      box-shadow: (inset 0px 0px 0px 2px rgba(33, 37, 41, 0.12999999523162842)),  ),
  figma-effects-focus-tree-view-kendo-treeview-item-focus-shadow: (
      box-shadow: (inset 0px 0px 0px 2px rgba(33, 37, 41, 0.15000000596046448)),  ),
  figma-effects-focus-tree-view-kendo-treeview-item-selected-focus-shadow: (
      box-shadow: (0px 0px 0px 2px rgba(13, 110, 253, 0.5)),  ),
);

@mixin effects-classes ($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

%tb-typography {
  @include typography-classes($tb-typography);
}

%tb-effects {
  @include effects-classes($tb-effects);
}