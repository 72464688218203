/* You can add global styles to this file, and also import other style files */
@import '../cyflare-one-light-theme/dist/scss';

.k-tilelayout .k-card {
  box-shadow: 0px 6px 13px 0 rgba(0, 0, 0, 0.125);
}

.page-template {
  font-family: 'DejaVu Sans', 'Arial', sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.page-template .header {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  color: #000;
}

.page-template .footer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-top: 1px solid #888;
  text-align: center;
  color: #888;
}

.k-loader {
  justify-content: center;
  display: flex;
}

input:read-only {
  background-color: #f8f9fa;
}

.mxh-80v {
  max-height: 80vh;
}

.mxh-80p {
  max-height: 80%;
}
