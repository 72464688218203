// Compatible with @progress/kendo-theme-bootstrap v.7.2.0

    .k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row >td{
    border-bottom-color: $tb-kendo-body-bg;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: 1px;
        
}
    .k-button.k-button-solid-primary{
    border-bottom-color: $tb-kendo-button-border;
            border-left-color: $tb-kendo-button-border;
            border-right-color: $tb-kendo-button-border;
            border-top-color: $tb-kendo-button-border;
            background-color: $tb-kendo-button-bg;
            background-image: none;
        
}
    .k-button.k-button-solid-base{
    background-color: $tb-figma-secondary-button-bg;
            border-bottom-color: $tb-figma-secondary-button-border;
            border-left-color: $tb-figma-secondary-button-border;
            border-right-color: $tb-figma-secondary-button-border;
            border-top-color: $tb-figma-secondary-button-border;
        
}
    .k-button{
    background-image: none;
        
}
    .k-button.k-button-flat.k-button-flat-primary{
    color: $tb-figma-base-text;
        
}
    .k-button.k-button-outline.k-button-outline-primary{
    color: $tb-figma-base-text;
        
}
    .k-button.k-button-outline.k-button-outline-primary.k-hover, .k-button.k-button-outline.k-button-outline-primary.k-state-hover, .k-button.k-button-outline.k-button-outline-primary.k-state-hovered, .k-button.k-button-outline.k-button-outline-primary:hover{
    color: $tb-figma-component-text-white;
            background-color: $tb-figma-primary-button-bg;
            background-image: none;
        
}
    .k-button.k-button-outline.k-button-outline-primary.k-active, .k-button.k-button-outline.k-button-outline-primary.k-state-active, .k-button.k-button-outline.k-button-outline-primary:active{
    color: $tb-figma-component-text-white;
            background-color: $tb-kendo-button-bg;
            background-image: none;
        
}
    .k-button.k-button-outline.k-button-outline-primary.k-selected{
    color: $tb-figma-component-text-white;
            background-color: $tb-kendo-button-bg;
            background-image: none;
        
}
    .k-button.k-button-outline.k-button-outline-primary.k-focus, .k-button.k-button-outline.k-button-outline-primary.k-state-focus, .k-button.k-button-outline.k-button-outline-primary.k-state-focused, .k-button.k-button-outline.k-button-outline-primary:focus{
    border-bottom-color: $tb-kendo-button-border;
            border-left-color: $tb-kendo-button-border;
            border-right-color: $tb-kendo-button-border;
            border-top-color: $tb-kendo-button-border;
        
}
    .k-fab.k-fab-solid-primary{
    background-color: $tb-kendo-button-bg;
            background-image: none;
            border-bottom-color: $tb-kendo-button-border;
            border-left-color: $tb-kendo-button-border;
            border-right-color: $tb-kendo-button-border;
            border-top-color: $tb-kendo-button-border;
            outline-color: $tb-kendo-button-border;
        
}
    .k-dropdownlist.k-picker-solid{
    background-color: $tb-kendo-pager-bg;
            border-bottom-color: $tb-kendo-input-border;
            border-left-color: $tb-kendo-input-border;
            border-right-color: $tb-kendo-input-border;
            border-top-color: $tb-kendo-input-border;
        
}
    .k-dropdownlist{
    background-image: none;
        
}
    .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid .k-input-button{
    background-color: $tb-kendo-button-bg;
            background-image: none;
        
}
    .k-dropdowntree.k-picker-solid{
    background-color: $tb-kendo-pager-bg;
            border-bottom-color: $tb-kendo-input-border;
            border-left-color: $tb-kendo-input-border;
            border-right-color: $tb-kendo-input-border;
            border-top-color: $tb-kendo-input-border;
        
}
    .k-dropdowntree{
    background-image: none;
        
}
    .k-dropdowntree .k-input-button.k-button.k-icon-button{
    background-color: $tb-figma-secondary-button-bg;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row,.k-grid.k-grid-md  .k-grid-header .k-table-row{
    background-color: initial;
            background-image: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
        
}
    .k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row td.k-focus,.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row  td.k-state-focus,.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row  td.k-state-focused,.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row  td:focus{
    background-color: $tb-figma-body-primary;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row.k-selected >td{
    background-color: $tb-figma-primarycolor-25;
            background-image: none;
        
}
    .k-button.k-button-solid-primary.k-hover, .k-button.k-button-solid-primary.k-state-hover, .k-button.k-button-solid-primary.k-state-hovered, .k-button.k-button-solid-primary:hover{
    background-color: $tb-figma-primary-button-hovered-bg;
            background-image: none;
        
}
    .k-button.k-button-solid-primary.k-active, .k-button.k-button-solid-primary.k-state-active, .k-button.k-button-solid-primary:active{
    background-color: $tb-figma-primary-button-active-bg;
            background-image: none;
        
}
    .k-button.k-hover, .k-button.k-state-hover, .k-button.k-state-hovered, .k-button:hover{
    background-color: $tb-figma-hovered-bg;
            background-image: none;
        
}
    .k-button.k-active, .k-button.k-state-active, .k-button:active{
    background-color: $tb-figma-secondary-button-active-bg;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row .k-header.k-table-th .k-cell-inner .k-link .k-column-title,.k-grid.k-grid-md  .k-grid-header .k-table-row .k-header.k-table-th .k-cell-inner .k-link .k-column-title{
    color: $tb-kendo-body-text;
        
}
    .k-drawer-container.k-drawer-expanded.k-drawer-push .k-drawer.k-drawer-start .k-drawer-wrapper .k-drawer-items{
    flex-direction: column;
            background-color: initial;
            background-image: none;
        
}
    .k-drawer-container.k-drawer-expanded.k-drawer-push .k-drawer.k-drawer-start .k-drawer-wrapper .k-drawer-items .k-drawer-item.k-selected{
    justify-content: start;
            color: $tb-figma-base-text;
            border-right-color: $tb-kendo-button-bg;
            border-right-style: solid;
            border-right-width: $tb-kendo-appbar-gap;
            background-color: initial;
            background-image: none;
        
}
    .k-drawer-container.k-drawer-mini.k-drawer-push .k-drawer.k-drawer-start .k-drawer-wrapper .k-drawer-items .k-drawer-item.k-selected{
    background-color: $tb-kendo-button-bg;
            background-image: none;
        
}
    .k-form.k-form-horizontal .k-form-buttons{
    background-color: initial;
            background-image: none;
        
}
    .k-switch.k-switch-on .k-switch-track .k-switch-label-on{
    display: inline;
            background-color: initial;
            background-image: none;
            color: $tb-figma-component-text-white;
            font-size: smaller;
        
}
    .k-switch.k-switch-on .k-switch-thumb-wrap .k-switch-thumb{
    position: absolute;
            right: 0%;
            left: 200%;
        
}
    .k-switch.k-switch-off .k-switch-thumb-wrap .k-switch-thumb{
    display: inline;
            align-self: center;
        
}
    .k-switch.k-switch-off .k-switch-track .k-switch-label-off{
    display: inline;
            font-size: smaller;
        
}
    .k-switch.k-switch-off .k-switch-track{
    justify-content: right;
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
        
}
    .k-switch.k-switch-off.k-switch-md{
    width: 100px;
        
}
    .k-switch.k-switch-off{
    padding-bottom: 1px;
            padding-left: 1px;
            padding-right: 1px;
            padding-top: 1px;
        
}
    .k-switch.k-switch-on.k-switch-md{
    width: 100px;
        
}
    .k-switch.k-switch-on .k-switch-track{
    padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            background-color: $tb-kendo-color-success;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row.k-filter-row,.k-grid.k-grid-md  .k-grid-header .k-table-row.k-filter-row{
    background-color: $tb-kendo-pager-bg;
            background-image: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button{
    background-color: initial;
            background-image: none;
            outline-style: none;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-button{
    border-bottom-style: none;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-active.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-active.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button:active.k-button{
    border-bottom-style: none;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-active, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-active, .cyflare-appbar-dropdown-button.k-dropdown-button:active{
    outline-style: none;
            background-color: initial;
            background-image: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-focus, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focus, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focused, .cyflare-appbar-dropdown-button.k-dropdown-button:focus{
    background-color: initial;
            background-image: none;
            outline-style: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-focus.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focus.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focused.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button:focus.k-button{
    border-bottom-style: none;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-focus.k-button-solid-base, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focus.k-button-solid-base, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-focused.k-button-solid-base, .cyflare-appbar-dropdown-button.k-dropdown-button:focus.k-button-solid-base{
    box-shadow: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-hover, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-hover, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-hovered, .cyflare-appbar-dropdown-button.k-dropdown-button:hover{
    background-color: initial;
            background-image: none;
        
}
    .no-hover-dropdown-button.k-dropdown-button.k-hover.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-hover.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button.k-state-hovered.k-button, .cyflare-appbar-dropdown-button.k-dropdown-button:hover.k-button{
    border-bottom-style: none;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item{
    outline-style: solid;
            outline-width: 0px;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-active,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active{
    background-color: initial;
            background-image: none;
            border-bottom-color: $tb-kendo-color-primary;
            border-bottom-style: solid;
            border-bottom-width: 1px;
        
}
    .tertiary-button.k-button.k-selected.k-button-solid-base{
    color: $tb-kendo-component-text;
        
}
    .tertiary-button.k-button.k-focus.k-button-solid-base, .tertiary-button.k-button.k-state-focus.k-button-solid-base, .tertiary-button.k-button.k-state-focused.k-button-solid-base, .tertiary-button.k-button:focus.k-button-solid-base{
    box-shadow: none;
        
}
    .tertiary-button.k-button.k-focus, .tertiary-button.k-button.k-state-focus, .tertiary-button.k-button.k-state-focused, .tertiary-button.k-button:focus{
    border-bottom-color: $tb-kendo-color-primary;
            border-bottom-style: solid;
            border-bottom-width: 3px;
        
}
    .k-tilelayout.k-pos-relative.k-grid-flow-col{
    background-color: initial;
            background-image: none;
        
}
    .k-card .k-card-body >p{
    background-color: initial;
            background-image: none;
        
}
    .k-card .k-card-body{
    background-color: $tb-figma-bg-color;
            background-image: none;
        
}
    .k-tilelayout.k-pos-relative.k-grid-flow-col .k-tilelayout-item.k-card.k-cursor-grab .k-tilelayout-item-body.k-card-body{
    background-color: $tb-kendo-card-bg;
            background-image: none;
        
}
    .tertiary-button.k-button{
    border-bottom-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            background-color: $tb-kendo-color-light;
            background-image: none;
        
}
    .tertiary-button.k-button.k-rounded-md{
    border-bottom-right-radius: unset;
            border-bottom-left-radius: unset;
        
}
    .tertiary-button.k-button.k-active, .tertiary-button.k-button.k-state-active, .tertiary-button.k-button:active{
    background-color: initial;
            background-image: none;
        
}
    .tertiary-button.k-button.k-hover, .tertiary-button.k-button.k-state-hover, .tertiary-button.k-button.k-state-hovered, .tertiary-button.k-button:hover{
    background-color: $tb-figma-gray-200;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody .k-table-row.k-master-row.k-hover,.k-grid.k-grid-md .k-table .k-table-tbody .k-table-row.k-master-row.k-state-hover,.k-grid.k-grid-md .k-table .k-table-tbody .k-table-row.k-master-row.k-state-hovered,.k-grid.k-grid-md .k-table .k-table-tbody .k-table-row.k-master-row:hover{
    background-color: $tb-figma-hovered-bg;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-hover >td,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-state-hover >td,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-state-hovered >td,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row:hover >td{
    background-color: initial;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row >td{
    background-color: initial;
            background-image: none;
        
}
    .k-button.k-selected{
    background-color: $tb-figma-colors-secondary-button-selected;
            background-image: none;
        
}
    .k-button.k-selected.k-button-solid-base{
    color: $tb-kendo-color-primary;
        
}
    .k-button.k-button-solid-primary.k-selected{
    background-color: $tb-figma-primary-button-active-border;
            background-image: none;
        
}
    .k-button.k-button-solid-primary.k-focus, .k-button.k-button-solid-primary.k-state-focus, .k-button.k-button-solid-primary.k-state-focused, .k-button.k-button-solid-primary:focus{
    box-shadow: 0px 0px 0px 0.25rem $tb-kendo-component-border;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row .k-header.k-table-th .k-cell-inner .k-link,.k-grid.k-grid-md  .k-grid-header .k-table-row .k-header.k-table-th .k-cell-inner .k-link{
    border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row .k-header.k-table-th,.k-grid.k-grid-md  .k-grid-header .k-table-row .k-header.k-table-th{
    color: $tb-figma-base-text;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row{
    background-color: initial;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-grid-header{
    background-color: initial;
            background-image: none;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-active .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active .k-link{
                    @extend %tb-typography-figma-base-text-bold;
          outline-color: transparent;
            color: $tb-kendo-color-light;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-active .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-state-active .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item:active .k-link{
    background-color: $tb-kendo-color-primary;
            background-image: none;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item .k-link{
                    @extend %tb-typography-figma-base-text-bold;
          color: $tb-figma-gray-600;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-active.k-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active.k-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active.k-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-active.k-state-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-active.k-state-hovered .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-active:hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active.k-state-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active.k-state-hovered .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item.k-state-active:hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active.k-state-hover .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active.k-state-hovered .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset  .k-item.k-tabstrip-item:active:hover .k-link{
    outline-color: transparent;
        
}
    .k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid .k-input-button{
    background-color: $tb-figma-secondary-button-bg;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody{
    background-color: initial;
            background-image: none;
        
}
    .k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-hover,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-state-hover,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row.k-state-hovered,.k-grid.k-grid-md .k-table .k-table-tbody .k-master-row.k-table-row.k-alt.k-table-alt-row:hover{
    background-color: $tb-figma-hovered-bg;
            background-image: none;
        
}
    .k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-focus,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-state-focus,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-state-focused,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item:focus{
    box-shadow: inset 0px 0px 0px 1px rgba(33, 37, 41, 0.15);
        
}
    .k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-hover,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-state-hover,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item.k-state-hovered,.k-list.k-list-md .k-list-content .k-list-ul .k-list-item:hover{
    background-color: $tb-figma-primarycolor-12;
            background-image: none;
        
}
    .k-menu-popup .k-group.k-menu-group .k-item.k-menu-item.k-focus .k-link.k-menu-link,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item.k-state-focus .k-link.k-menu-link,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item.k-state-focused .k-link.k-menu-link,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item:focus .k-link.k-menu-link{
    box-shadow: inset 0px 0px 0px 1px rgba(33, 37, 41, 0.15);
        
}
    .k-menu-popup .k-group.k-menu-group .k-item.k-menu-item .k-link.k-menu-link.k-hover,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item .k-link.k-menu-link.k-state-hover,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item .k-link.k-menu-link.k-state-hovered,.k-menu-popup .k-group.k-menu-group .k-item.k-menu-item .k-link.k-menu-link:hover{
    background-color: $tb-figma-primarycolor-12;
            background-image: none;
        
}
    .k-tilelayout.k-pos-relative.k-grid-flow-col .k-tilelayout-item.k-card.k-cursor-grab{
    border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            box-shadow: 1px 3px 5px 1px $tb-figma-base-bg;
        
}
    .k-tilelayout.k-pos-relative.k-grid-flow-col .k-tilelayout-item.k-card.k-cursor-grab .k-tilelayout-item-header.k-card-header{
    border-bottom-color: $tb-kendo-editor-highlighted-bg;
            border-left-color: $tb-kendo-editor-highlighted-bg;
            border-right-color: $tb-kendo-editor-highlighted-bg;
            border-top-color: $tb-kendo-editor-highlighted-bg;
        
}
    .k-tilelayout.k-pos-relative.k-grid-flow-col .k-tilelayout-item.k-card.k-cursor-grabbing{
    border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            box-shadow: 1px 3px 3px 2px $tb-figma-base-bg;
        
}
    .k-card{
                    @extend %tb-effects-kendo-appbar-box-shadow;
          border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        
}
    .k-card .k-card-header{
    background-color: $tb-figma-base-bg;
            background-image: none;
            color: $tb-kendo-body-text;
        
}
    .k-card .k-actions.k-card-actions{
    background-color: $tb-figma-bg-color;
            background-image: none;
        
}
    .k-tabstrip .k-tabstrip-content.k-active,.k-tabstrip .k-tabstrip-content.k-state-active,.k-tabstrip .k-tabstrip-content:active{
    border-right-color: rgb(222, 226, 230);
            border-right-style: none;
            border-right-width: 1px;
            border-bottom-color: rgb(222, 226, 230);
            border-bottom-style: none;
            border-bottom-width: 1px;
            border-left-color: rgb(222, 226, 230);
            border-left-style: none;
            border-left-width: 1px;
        
}
    .k-chip.k-chip-solid-base{
    background-color: $tb-figma-base-bg;
            color: $tb-kendo-card-text;
        
}
    .k-chip{
    background-image: none;
        
}
    .k-chip.k-chip-outline.k-chip-outline-base{
    color: $tb-kendo-base-text;
            border-bottom-color: $tb-figma-base-text;
            border-left-color: $tb-figma-base-text;
            border-right-color: $tb-figma-base-text;
            border-top-color: $tb-figma-base-text;
            background-color: $tb-kendo-component-bg;
        
}
    .k-chip.k-chip-outline{
    background-image: none;
        
}
    .k-chip.k-chip-outline.k-hover,.k-chip.k-chip-outline.k-state-hover,.k-chip.k-chip-outline.k-state-hovered,.k-chip.k-chip-outline:hover{
    color: $tb-figma-base-text;
            background-color: $tb-figma-base-bg;
            background-image: none;
        
}
    .k-chip.k-chip-outline.k-selected{
    color: $tb-figma-base-text;
            background-color: $tb-figma-base-bg;
            background-image: none;
        
}
    .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-disabled .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item.k-state-disabled .k-link,.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items.k-reset .k-item.k-tabstrip-item:disabled .k-link{
    color: $tb-figma-gray-500;
        
}